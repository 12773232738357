import React, { useEffect, useState } from 'react'
import { Table, TableHead, TableRow, TableCell, makeStyles, TablePagination, TableSortLabel } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    paginationDiv:{
        "& .MuiIconButton-root":{
            color: theme.palette.primary.text,
        },
        "& .Mui-disabled":{
            color: "#dadada !important",
        },
        "& .MuiTypography-root":{
            color:theme.palette.primary.text,
            [theme.breakpoints.down('lg')]: {
                fontSize:"1.3rem !important"
               },
            [theme.breakpoints.down('md')]: {
               fontSize:"1.2rem !important"
              },
              [theme.breakpoints.down('sm')]: {
                fontSize:"1rem !important"  
              }
        },
        "& .MuiSelect-select":{
            color:theme.palette.primary.text,
            [theme.breakpoints.down('lg')]: {
                fontSize:"1.3rem !important"
               },
            [theme.breakpoints.down('md')]: {
               fontSize:"1.2rem !important"
              },
              [theme.breakpoints.down('sm')]: {
                fontSize:"1rem !important"  
              }
        }
    },
    format:{
        [theme.breakpoints.up(900)]:{
        "& [name=date] , [name=contactNumber] , [name=id] , [name=qty] ":{
            textAlign:"center !important"
        },
        "& [name=amount]":{
            textAlign:"right !important"
        },
        "& [name=totalamount]":{
            textAlign:"right !important"
        }
    },
        "& .MuiTableCell-stickyHeader":{
            
            [theme.breakpoints.up(900)]:{
            backgroundColor:"inherit",
            paddingTop:".5rem",
            },
            "& .MuiTableSortLabel-root":{
            flexDirection:"column",
            
            }
        }
    }
   
}))

export default function useTable(records, headCells,filterFn,ref,breakOn = 'medium',totalPages,currentPage,totalItems,getallitems,EmptyItems,useUseEffect,setLoading,dependenceies=[],getFilteredOrSearchItems,SearchOrDependenceies=[true]) {
    
    const classes = useStyles();
    let tableClass = 'table-container__table';
    const pages = [3,5, 10, 25]
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState()
    const handleSortRequest = cellId => {
        const isAsc = orderBy === cellId && order === "desc";
        setOrder(isAsc ? 'asc' : 'desc');
        setOrderBy(cellId)
    }
    if(breakOn === 'small') {
        tableClass += ' table-container__table--break-sm';
      }else if(breakOn === 'medium') {
        tableClass += ' table-container__table--break-md';
      }else if(breakOn === 'large') {
        tableClass += ' table-container__table--break-lg';
      }
    const TblContainer = props => (
        
        <Table stickyHeader className={tableClass+" "+ classes.format}>
            {props.children}
        </Table>
    )

    const TblHead = props => {
        

        return (<>
            
        <TableHead>
            <TableRow>
                {
                    headCells.map(headCell => (
                        <TableCell name={headCell.name} key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}>
                            {headCell.disableSorting ? <> {headCell.label} </> :
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'desc'}
                                    onClick={() => { handleSortRequest(headCell.id) }}>
                                    {headCell.label}
                                </TableSortLabel>
                            }
                        </TableCell>))
                }
            </TableRow>
        </TableHead></>)
    }
    
    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        console.log("scrollTop"+scrollTop)
        console.log("clientHeight"+clientHeight)
        console.log("scrollHeight"+scrollHeight)
        if (scrollHeight - scrollTop === clientHeight && page < totalPages) {
          setPage(page + 1);
          console.log(page)
        }
      };


  useEffect(() => {
    if(useUseEffect === false){
        return null
    }else if(page > 0 && page < totalPages && records.length < totalItems){
        if(SearchOrDependenceies[0]){
            getallitems({page:page,limit:rowsPerPage},setLoading)
    }else{
        getFilteredOrSearchItems(page,rowsPerPage)
    }
    console.log("useTable page use effact page ="+page)
    setLoading(true)
    }
    else if(page > totalPages && records.length > totalItems){
        setLoading(false)
    }
},[page])
useEffect(() => {
    if(useUseEffect === false){
        return null}
        else{
    EmptyItems()
    getallitems({page:page,limit:rowsPerPage},setLoading)
    setLoading(true)
        }
    
},[...dependenceies])




    const TblPagination = () => (<TablePagination
    className={classes.paginationDiv}
        component="div"
        page={page}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={totalItems}
    />)

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const recordsAfterPagingAndSorting =  () => (
        stableSort( filterFn.fn(records), getComparator(order, orderBy))
            
    )

    return {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        rowsPerPage,
        page,
        setPage,
        handleScroll,
        handleSortRequest,
        setOrder
    }
}
