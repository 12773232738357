import React, { useState,useEffect } from 'react'
import { makeStyles } from "@material-ui/core";

export function useForm(initialFValues, validateOnChange = false, validate) {


    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});

    const handleInputChange = e => {
        let { name, value } = e.target
        if(name === "Customer_Mobile"){
            value = value.replace(/[^a-zA-Z0-9]/g, '');
        }
       
        setValues({
            ...values,
            [name]: value
        })
        console.log(values)
        console.log(errors)
        if (validateOnChange)
            validate({ [name]: value })
    }
    const handleInputNunberChange = (e,val) => {
        let { name, value } = e.target
        setValues({
            ...values,
            [name]: val
        })
        console.log(values)
        console.log(errors)
        if (validateOnChange)
            validate({ [name]: value })
    }
    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }


    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleInputNunberChange,
        resetForm

    }
}


const useStyles = makeStyles(theme => ({
    root: {
         // "& .MuiGrid-container":{

        
        // '&  > *': {
        //     width:`calc(100% - 8px) `,
        //     margin: theme.spacing(1)
        // }
    // },
 
        "& .MuiTypography-h6":{
            fontSize:'1.6rem'
        },
        '& .makeStyles-root-33':{
            fontSize:"1rem"
        },
        "& .MuiFormLabel-root":{
            color: theme.palette.primary.dark,
            fontSize:"1.6rem"
        }
    }
}))

export function Form(props) {

    const classes = useStyles();
    const { children, ...other } = props;
    return (
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}

