import React, { useEffect ,useState} from 'react'
import { Grid,makeStyles } from '@material-ui/core';
import {MyLocation} from "@material-ui/icons"
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import ConfirmDialog from "../../components/ConfirmDialog";
import misc from "../../fnc/misc"


const useStyles = makeStyles(theme => ({
    root:{
        "& > :not(:last-child)":{
            marginBottom:"1rem"
        },
    },
    grid1: {
        display:"flex",
        "& > :not(:last-child)":{
            marginRight:"1rem"
        },
        justifyContent:"space-between",
        [theme.breakpoints.down(600)]:{
            flexDirection:"column",
            "& > :not(:last-child)":{
                marginBottom:"1rem"
            },
        },
    },
    grid1half:{
        display:"flex",
        justifyContent:"space-between",
        "& > :not(:last-child)":{
            marginRight:"1rem"
        },
        // [theme.breakpoints.down(600)]:{
        //     // flexDirection:"column",
        // },
    },
    gridName:{
        width:"50%",
        [theme.breakpoints.down(600)]:{
            width:"100%",

        },
       },
       gridPhone:{
        width:"40%",
        [theme.breakpoints.down(600)]:{
            width:"40%",
        },
       },
       gridActive:{
        display:"flex",
        width:"30%",
        [theme.breakpoints.down(600)]:{
            width:"30%",
        },
        "& .MuiTypography-body1 , svg":{
        fontSize:"2rem !important"
        }
        },
        gridSortNumber:{
            display:"flex",
        width:"30%",
        [theme.breakpoints.down(600)]:{
            width:"30%",
        },
        "& .MuiTypography-body1 , svg":{
        fontSize:"2rem !important"
        }
        },
        gridAddress:{
            display:"flex",
            width:"100%",
            justifyContent:"space-between"
            },
        inputAddress:{
            width:"60%"
        },
        buttonLocation:{
            width:"30%",
            height:"4rem"
        },
    grid2:{
        display:"flex",
        "& > :not(:last-child)":{
            marginRight:"1rem"
        },
        justifyContent:"flex-start",
        [theme.breakpoints.down(600)]:{
            flexDirection:"column",
            "& > :not(:last-child)":{
                marginBottom:"1rem"
            },
        },
       },
    gridMorning:{
        border: ".1rem solid #c4c4c4", 
        borderRadius:".4rem",
        padding:"1rem",
        position:"relative",
        width:"33.33%",
        "& > :not(:last-child)":{
            marginBottom:"1rem"
        },
        [theme.breakpoints.down(600)]:{
            width:"100%",
          
       
        },
     "& .LabelMorning":{
        top:"-.7rem",
         position:"absolute",
         backgroundColor:"white",
         color: theme.palette.primary.dark,
         fontSize:"1rem",
         padding:"0rem .4rem 0rem .4rem"
     },
     "&  .MuiInputBase-input":{
        textAlign:"right"
    }
     
    },
    gridEvening:{
        border: ".1rem solid #c4c4c4", 
        borderRadius:".4rem",
        padding:"1rem",
        width:"33.33%",
        textAlign:"right",
        position:"relative",
        "& > :not(:last-child)":{
            marginBottom:"1rem"
        },
        [theme.breakpoints.down(600)]:{
            width:"100%",
        
       
        },
      "& .LabelEvening":{
          top:"-.7rem",
         position:"absolute",
         backgroundColor:"white",
         color: theme.palette.primary.dark,
         fontSize:"1rem",
         padding:"0rem .2rem 0rem .2rem"
     },
     "&  .MuiInputBase-input":{
         textAlign:"right"
     }
    },
    grid4:{
        border: ".1rem solid #c4c4c4", 
        borderRadius:".4rem",
        padding:"1rem",
        width:"33.33%",
        position:"relative",
        "& > :not(:last-child)":{
            marginBottom:"1rem"
        },
        [theme.breakpoints.down(600)]:{
            width:"100%",
            "& > :not(:last-child)":{
                marginBottom:"1rem"
            },
       
        },
      "& .LabelRate":{
          top:"-.7rem",
         position:"absolute",
         backgroundColor:"white",
         color: theme.palette.primary.dark,
            fontSize:"1rem",
         padding:"0rem .4rem 0rem .4rem"
     },
     "&  .MuiInputBase-input":{
        textAlign:"right"
    }
    },
    grid5: {
        display:"flex",
        justifyContent:"space-between",
        marginTop:"1rem",
        "& > :not(:last-child)":{
            marginRight:"1rem"
        },
        [theme.breakpoints.down(600)]:{
            flexDirection:"column",
            "& > :not(:last-child)":{
                marginBottom:"1rem"
            },
        },
    },
    receivable:{
        width:"25%",
        
        [theme.breakpoints.down(600)]:{
            width:"100%",

        },
    },
    payable:{
        width:"25%",
        [theme.breakpoints.down(600)]:{
            width:"100%",

        },
    },
    detail:{
        width:"50%",
        [theme.breakpoints.down(600)]:{
            width:"100%",

        },
    },
    
    grid6:{
        display:"flex",
        justifyContent:"space-between",
        width: "15rem",
        margin:".8rem .0rem 0rem 0rem",
        
    }
    

}))


export default function CustomerForm(props) {

    const [initialFValues,setInitialFValues] = useState({
        Customer_Name:"",
        Customer_Urdu_Name:"",
        Customer_Mobile:"",
        Customer_Address:"",
       Customer_Morning_Milk_QTY:"0",
       Customer_Evening_Milk_QTY:"0",
       Customer_Morning_Yog_QTY:"0",
       Customer_Evening_Yog_QTY:"0",
       Customer_Morning_Ghee_QTY:"0",
       Customer_Evening_Ghee_QTY:"0",
       Customer_Milk_Rate:"0",
       Customer_Yog_Rate:"0",
       Customer_Ghee_Rate:"0",
       Customer_Active:1,
       Trans_ID:null,
       Debit_Amount:0,
       Credit_Amount:0,
       Customer_Sort_Number:0,
       Trans_Detail:"",
       Customer_Long:"",
       Customer_Lat:""
    })
    
    const { addOrEdit, recordForEdit , formLanguage} = props
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const classes = useStyles()
    const [coords,setCoords] = useState({lat:0,long:0})
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('Customer_Name' in fieldValues)
            temp.Customer_Name = fieldValues.Customer_Name ? "" : "Customer_Name is not valid."
        if ('Customer_Mobile' in fieldValues)
            temp.Customer_Mobile = fieldValues.Customer_Mobile ? "" : "Customer_Mobile is not valid."
        if ('Customer_Address' in fieldValues)
            temp.Customer_Address = fieldValues.Customer_Address ? "" : "Customer_Address is not valid."
        // if ('Customer_Morning_Milk_QTY' in fieldValues)
        //     temp.Customer_Morning_Milk_QTY = fieldValues.Customer_Morning_Milk_QTY > 0 ? "" : "Customer_Morning_Milk_QTY is not valid."
        // if ('Customer_Evening_Milk_QTY' in fieldValues)
        //     temp.Customer_Evening_Milk_QTY = fieldValues.Customer_Evening_Milk_QTY ? "" : "Customer_Evening_Milk_QTY is not valid."
        // if ('Customer_Morning_Yog_QTY' in fieldValues)
        //     temp.Customer_Morning_Yog_QTY = fieldValues.Customer_Morning_Yog_QTY ? "" : "Customer_Morning_Yog_QTY is not valid."
        // if ('Customer_Evening_Yog_QTY' in fieldValues)
        //     temp.Customer_Evening_Yog_QTY = fieldValues.Customer_Evening_Yog_QTY ? "" : "Customer_Evening_Yog_QTY is not valid."
        // if ('Customer_Morning_Ghee_QTY' in fieldValues)
        //     temp.Customer_Morning_Ghee_QTY = fieldValues.Customer_Morning_Ghee_QTY ? "" : "Customer_Morning_Ghee_QTY is not valid."
        // if ('Customer_Evening_Ghee_QTY' in fieldValues)
        //     temp.Customer_Evening_Ghee_QTY = fieldValues.Customer_Evening_Ghee_QTY ? "" : "Customer_Evening_Ghee_QTY is not valid."
        // if ('Customer_Milk_Rate' in fieldValues)
        //     temp.Customer_Milk_Rate = fieldValues.Customer_Milk_Rate ? "" : "Customer_Milk_Rate is not valid."
        // if ('Customer_Yog_Rate' in fieldValues)
        //     temp.Customer_Yog_Rate = fieldValues.Customer_Yog_Rate ? "" : "Customer_Yog_Rate is not valid."
        // if ('Customer_Ghee_Rate' in fieldValues)
        //     temp.Customer_Ghee_Rate = fieldValues.Customer_Ghee_Rate ? "" : "Customer_Ghee_Rate is not valid."
        // if ('Customer_Active' in fieldValues)
        //     temp.Customer_Active = fieldValues.Customer_Active ? "" : "Customer_Active is not valid."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleInputNunberChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = () => {
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
        setValues({...recordForEdit})
    }, [recordForEdit])
    // useEffect(()=>{
    //     setValues({...values,Customer_Lat:coords.lat,Customer_Long:coords.long})
    // },[coords])
    const onAddOrUdpateCustomer = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        handleSubmit()
    }
    
    const onResetForm = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       resetForm()
    }


    const getLocation=()=> {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(setPosition);
        } else {
          console.log("Geolocation is not supported by this browser.")
        }
      }
      
      const setPosition=(position)=> {
    // setInitialFValues({...initialFValues,Customer_Lat:position.coords.latitude,Customer_Long:position.coords.longitude})
    // setCoords({lat:position.coords.latitude,long:position.coords.longitude})
    setValues({...values,Customer_Lat:position.coords.latitude,Customer_Long:position.coords.longitude})
    console.log(values)
      }
     
    return (
        <>
        <Form className={classes.root}  onSubmit={(e) => {
            e.preventDefault()
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to Submit',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onAddOrUdpateCustomer() }
                                })
                            }}>
            <div className={classes.grid1}>
            <div className={classes.gridName}>
                    <Controls.Input
                        name="Customer_Name"
                        label={formLanguage.Input_Customer_Name}
                        value={misc.titleCase(values.Customer_Name)}
                        onChange={handleInputChange}
                        error={errors.Customer_Name}
                    />
            </div>
            <div className={classes.gridName}>
                    <Controls.Input
                        varient="urdu"
                        name="Customer_Urdu_Name"
                        label={formLanguage.Input_Customer_Urdu_Name}
                        value={values.Customer_Urdu_Name}
                        onChange={handleInputChange}
                        error={errors.Customer_Urdu_Name}
                    />
            </div>
            </div>
            <div className={classes.grid1half}>
            <div className={classes.gridPhone}>
                    <Controls.InputMobile
                        name="Customer_Mobile"
                        label={formLanguage.Input_Customer_Mobile}
                        value={values.Customer_Mobile}
                        onChange={handleInputChange}
                        error={errors.Customer_Mobile}
                    />
            </div>
           <div className={classes.gridActive}>
                    <Controls.Checkbox
                        name="Customer_Active"
                        label={formLanguage.Input_Customer_Status}
                        value={values.Customer_Active}
                        onChange={handleInputChange}
                        error={errors.Customer_Active}
                        />
                </div>
                <div className={classes.gridSortNumber}>
                    <Controls.InputDecimalCustom
                        name="Customer_Sort_Number"
                        label={formLanguage.Input_Customer_Sort_Number}
                        varient="amount"
                        value={values.Customer_Sort_Number}
                        onChange={handleInputChange}
                        error={errors.Customer_Sort_Number}
                    />
                </div>
                </div>
            <div className={classes.gridAddress}>
            <div className={classes.inputAddress}>
            <Controls.Input
                        name="Customer_Address"
                        label={formLanguage.Input_Customer_Address}
                        value={values.Customer_Address}
                        onChange={handleInputChange}
                        error={errors.Customer_Address}
                        />
                        </div>
                        <div className={classes.buttonLocation}>
             <Controls.Button
                        keyupfire={false}
                            text="Refresh"
                            startIcon={<MyLocation/>}
                            onClick={getLocation}
                            />
                            </div>
            </div>
            <div className={classes.grid2}>
                <div className={classes.gridMorning}>
            <label className="LabelMorning">{formLanguage.Label_Customer_Morning_Qty}</label>
                    <Controls.InputDecimalCustom
                     placeholder="0.00"
                        name="Customer_Morning_Milk_QTY"
                        label={formLanguage.Input_Customer_Milk}
                        currencySymbol=""
                        value={values.Customer_Morning_Milk_QTY}
                        onChange={handleInputChange}
                        error={errors.Customer_Morning_Milk_QTY}
                    />
                    
                    <Controls.InputDecimalCustom
                        placeholder="0.00"
                        name="Customer_Morning_Yog_QTY"
                        label={formLanguage.Input_Customer_Yog}
                        currencySymbol=""
                        value={values.Customer_Morning_Yog_QTY}
                        onChange={handleInputChange}
                        error={errors.Customer_Morning_Yog_QTY}
                    />
                     <Controls.InputDecimalCustom
                      placeholder="0.00"
                        name="Customer_Morning_Ghee_QTY"
                        label={formLanguage.Input_Customer_Others}
                        currencySymbol=""
                        value={values.Customer_Morning_Ghee_QTY}
                        onChange={handleInputChange}
                        error={errors.Customer_Morning_Ghee_QTY}
                    />
                    </div>
                    
                    <div className={classes.gridEvening}>
                    <label className="LabelEvening">{formLanguage.Label_Customer_Evening_Qty}</label>
                    <Controls.InputDecimalCustom
                     placeholder="0.00"
                        name="Customer_Evening_Milk_QTY"
                        label={formLanguage.Input_Customer_Milk}
                        currencySymbol=""
                        value={values.Customer_Evening_Milk_QTY}
                        onChange={handleInputChange}
                        error={errors.Customer_Evening_Milk_QTY}
                    />
                    <Controls.InputDecimalCustom
                     placeholder="0.00"
                        name="Customer_Evening_Yog_QTY"
                        label={formLanguage.Input_Customer_Yog}
                        currencySymbol=""
                        value={values.Customer_Evening_Yog_QTY}
                        onChange={handleInputChange}
                        error={errors.Customer_Evening_Yog_QTY}
                    />
                   
                    <Controls.InputDecimalCustom
                     placeholder="0.00"
                        currencySymbol=""
                        name="Customer_Evening_Ghee_QTY"
                        label={formLanguage.Input_Customer_Others}
                        value={values.Customer_Evening_Ghee_QTY}
                        onChange={handleInputChange}
                        error={errors.Customer_Evening_Ghee_QTY}
                    />
                   </div>
      
                <div className={classes.grid4}>
                <label className="LabelRate">{formLanguage.Label_Customer_Rate}</label>
                    <Controls.InputDecimalCustom
                     placeholder="0.00"
                     currencySymbol=""
                        name="Customer_Milk_Rate"
                        label={formLanguage.Input_Customer_Milk}
                       
                        value={values.Customer_Milk_Rate}
                        onChange={handleInputChange}
                        error={errors.Customer_Milk_Rate}
                    />
                     <Controls.InputDecimalCustom
                      placeholder="0.00"
                      currencySymbol=""
                        name="Customer_Yog_Rate"
                        label={formLanguage.Input_Customer_Yog}
                        value={values.Customer_Yog_Rate}
                        onChange={handleInputChange}
                        error={errors.Customer_Yog_Rate}
                    />
                     <Controls.InputDecimalCustom
                      placeholder="0.00"
                      currencySymbol=""
                        name="Customer_Ghee_Rate"
                        label={formLanguage.Input_Customer_Others}
                        value={values.Customer_Ghee_Rate}
                        onChange={handleInputChange}
                        error={errors.Customer_Ghee_Rate}
                    />
                </div>
                </div>
                <div className={classes.grid5}>
            <div className={classes.payable}>
            <Controls.InputDecimalCustom
                      placeholder="0.00"
                      currencySymbol=""
                        varient="amount"
                        name="Credit_Amount"
                        label={formLanguage.Input_Customer_Payable}
                        value={values.Credit_Amount}
                        onChange={handleInputChange}
                        error={errors.Credit_Amount}
                    />
            </div>
            <div className={classes.receivable}>
            <Controls.InputDecimalCustom
                      placeholder="0.00"
                      currencySymbol=""
                        name="Debit_Amount"
                        varient="amount"
                        label={formLanguage.Input_Customer_Receivable}
                        value={values.Debit_Amount}
                        onChange={handleInputChange}
                        error={errors.Debit_Amount}
                    />
            </div>
            <div className={classes.detail}>
                    <Controls.Input
                        name="Trans_Detail"
                        label={formLanguage.Input_Customer_Detail}
                        value={values.Trans_Detail}
                        onChange={handleInputChange}
                        error={errors.Trans_Detail}
                    />
            </div>
            </div>
                    <div className={classes.grid6}>
                        <Controls.Button
                        keyupfire={false}
                            type="submit"
                            text={!recordForEdit?formLanguage.Button_Add:formLanguage.Button_Update} />
                        {/* <Controls.Button
                        keyupfire={false}
                            text="Cancel"
                            color="default"
                            onClick={() => {
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to reset',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onResetForm() }
                                })
                                }}/> */}
                    </div>

        </Form>
        <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
    />
    </>
    )
}
