import React, { useState, useRef, useEffect } from "react";
import ReceiptForm from "./ReceiptForm";
import OrderForm from "./OrderForm";
import PageHeader from "../../components/PageHeader";
import axios from "axios";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Box,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import { connect } from "react-redux";
import { Search, Receipt, AccountBalance, Print } from "@material-ui/icons";
import Popup from "../../components/Popup";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import {
  GetAccountsBalances,
  EmptyAccountsBalances,
  DeleteAccountsBalance,
} from "../../redux/actions/AccountsBalanceAction";
import jsPDF from "jspdf";
import misc from "../../fnc/misc";
import "jspdf-autotable";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .pageHeaderPageInfo": {
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
  },
  pageContent: {
    padding: theme.spacing(3, 3, 0, 3),
    height: `calc(${window.innerHeight}px - 200px)`,

    [theme.breakpoints.down("sm")]: {
      height: `calc(${window.innerHeight}px - 200px)`,
    },
    [theme.breakpoints.down("xs")]: {
      height: `calc(${window.innerHeight}px - 170px)`,
    },

    overflow: "hidden",
    "& .MuiGrid-item > *": {
      maxWidth: `calc(100% - 1rem) !important`,
      width: `calc(100% - 1rem) !important`,
      marginRight: `1rem !important`,
    },
    /// balance table css
    "& .table-container": {
      height: `calc(${window.innerHeight}px - 250px)`,
      overflow: "auto",
      "& thead": {
        "&  tr": {
          "& [name=name]": {
            width: "25rem !important",
          },

          "& [name=amount]": {
            width: "15rem !important",
          },

          "& [name=mobile]": {
            width: "20rem !important",
          },

          [theme.breakpoints.down(900)]: {
            "& [name=name]": {
              width: "auto !important",
            },

            "& [name=amount]": {
              width: "auto !important",
            },
            "& [name=mobile]": {
              width: "auto !important",
            },
          },
        },
      },
      "& tbody": {
        "&  tr": {
          "& td": {
            [theme.breakpoints.down(900)]: {
              paddingLeft: "20rem",
            },
            [theme.breakpoints.down(539)]: {
              paddingLeft: "15rem",
            },
          },

          "& [name=name]": {
            width: "25rem !important",
          },
          "& [name=amount]": {
            width: "15rem !important",
          },

          "& [name=mobile]": {
            width: "20rem !important",
          },
          [theme.breakpoints.down(900)]: {
            "& [name=name]": {
              width: "auto !important",
            },
            "& [name=amount]": {
              width: "auto !important",
            },
            "& [name=mobile]": {
              width: "auto !important",
            },
          },
        },
      },
      "& [name=actions]": {
        "& svg": {
          visibility: "hidden",
        },
      },
      "& #scroll_Btn": {
        margin: "auto",
        display: "none",
        [theme.breakpoints.between("xs", "sm")]: {
          display: "block",
        },
      },
    },
  },
  Toolbar: {},

  ToolbarItems: {
    display: "flex",
    alignItems: "center",
    // [theme.breakpoints.between("xs","sm")]:{
    //     paddingLeft:"1rem",
    //     paddingRight:"1rem"

    // },
  },

  buttonContainerGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "2rem",
    },
    "& button": {
      width: "32.333%",
      [theme.breakpoints.between("md", "xl")]: {
        marginRight: ".3rem",
      },
    },
  },
  newButtonMobile: {
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  newButton: {
    height: "4rem",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  ActionBtnDiv: {
    [theme.breakpoints.down(900)]: {
      // display:"flex !important",
      // flexDirection:"column"
      "& > *": {
        width: "4rem",
        height: "3rem",
        padding: ".5rem",
      },
      "& svg": {
        fontSize: "1.6rem",
      },
    },
  },
}));

const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "inherit",
  m: 1,
  padding: 0,
  border: 1,
  style: { width: "4rem", height: "4rem" },
  display: "flex",
  margin: "0rem",
  alignItems: "center",
  justifyContent: "center",
};

const AccountsBalances = (props) => {
  const headCells =
    props.accountBalanceLanguage.Account_Balance.Account_Balance_Table
      .Account_Balance_Table_Head;

  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [Ledger_Info, Set_Ledger_Info] = useState([]);
  const [Open_Popup_Ledger_Duration, Set_Open_Popup_Ledger_Duration] =
    useState(false);
  const [Ledger_Duration, Set_Ledger_Duration] = useState({
    Start_Date: misc.formatDateyearmonthday(new Date()),
    End_Date: misc.formatDateyearmonthday(new Date()),
  });
  const [Ledger_Customer, Set_Ledger_Customer] = useState({
    Customer_Name: "",
    Customer_Mobile: "",
  });
  const [filterFn, setFilterFn] = useState({
    fn: (accountsBalances) => {
      return accountsBalances;
    },
  });
  const [Open_Popup_Ledger, Set_Open_Popup_Ledger] = useState(false);
  const [LedgerTotal, Set_Ledger_Total] = useState({
    T_Debit: null,
    T_Credit: null,
    T_Balance: null,
  });
  const [openPopupReceipt, setOpenPopupReceipt] = useState(false);
  const [openPopupOrder, setOpenPopupOrder] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [searchAccountsBalanceName, setSearchAccountsBalanceName] =
    useState(null);
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    rowsPerPage,
    page,
    setPage,
    handleScroll,
    handleSortRequest,
    setOrder,
  } = useTable(
    props.AccountsBalances_List,
    headCells,
    filterFn,
    componentRef,
    "medium",
    props.totalPages,
    props.currentPage,
    props.totalAccountsBalances,
    props.getAllAccountsBalances,
    props.EmptyAccountsBalances,
    true,
    setLoading
  );
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (accountsBalances) => {
        if (target.value == "") return accountsBalances;
        else
          return accountsBalances.filter(
            (x) =>
              `${x.Customer_Name}${x.Customer_Mobile.toString()}`
                .toLowerCase()
                .includes(target.value) ||
              `${x.Customer_Name}${x.Customer_Mobile.toString()}`
                .toUpperCase()
                .includes(target.value) ||
              misc
                .capitalize(`${x.Customer_Name}${x.Customer_Mobile.toString()}`)
                .includes(target.value)
          );
      },
    });
  };

  // Ledger Print code start

  // generatering dates from 1 to end of month dinamically function start
  //   const update_dates = (bills, Balance, Receipts) => {
  //     let dates = [];
  //     let s_date = new Date(
  //       Ledger_Duration.getFullYear(),
  //       Ledger_Duration.getMonth(),
  //       0
  //     ); // 1 date of month
  //     const e_date = new Date(
  //       Ledger_Duration.getFullYear(),
  //       Ledger_Duration.getMonth() + 1,
  //       0
  //     ); // end date of month

  //     for (let i = s_date; i < e_date; ) {
  //       s_date.setDate(s_date.getDate() + 1);
  //       dates.push(misc.formatDateyearmonthday(s_date));
  //     }
  //     console.log("update_dates" + Balance);
  //     mergeArrays(dates, bills, Balance, Receipts);
  //   };
  // generatering dates from 1 to end of month dinamically function end

  // merge dates and Ledger_Info arrays based on date match function start
  //   const mergeArrays = async (arr1 = [], arr2 = [], Balance, Receipts) => {
  //     console.log("mergeArrays" + Balance);
  //     let res = [];
  //     res = arr1.map((date) => {
  //       const index = arr2.findIndex(
  //         (el) => misc.formatDateyearmonthday(el.Trans_Date) == date
  //       );
  //       const item = index !== -1 ? arr2[index] : {};
  //       return {
  //         date,
  //         ...item,
  //       };
  //     });
  //     await Set_Ledger_Info([
  //       res,
  //       {
  //         T_Milk: arr2.reduce((a, b) => a + (b.Milk || 0), 0),
  //         T_Yog: arr2.reduce((a, b) => a + (b.Yog || 0), 0),
  //         T_Others: arr2.reduce((a, b) => a + (b.Ghee || 0), 0),
  //         T_Amount: arr2.reduce((a, b) => a + (b.Amount || 0), 0),
  //         Balance: Balance != null ? Balance : 0,
  //         Receipts: Receipts != null ? Receipts : 0,
  //       },
  //     ]);
  //     console.log(Ledger_Info);
  //     PrintReceipt();
  //   };
  // merge dates and Ledger_Info arrays based on date match function end

  //  fetch receipt data function start
  const getLedger = (customer) => {
    console.log("get receipt data");
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post(
        "/api/getcustomerledger",
        { Customer_ID: customer.Customer_ID, Ledger_Duration: Ledger_Duration },
        { headers }
      )
      .then(async (res) => {
        if (res.data.iserror == true) {
          setNotify({
            isOpen: true,
            message: "Empty Receipt Record For this Customer",
            type: "error",
          });
        } else {
          console.log(res.data);
          const balance =
            res.data.OrderAndReceipts[0].Debit -
            res.data.OrderAndReceipts[0].Credit;
          let newArray = res.data.OrderAndReceipts;
          if (balance > 0) {
            newArray[0].Debit = balance;
            newArray[0].Credit = 0;
          } else {
            newArray[0].Credit = Math.abs(balance);
            newArray[0].Debit = 0;
          }
          console.log(newArray);
          await Set_Ledger_Info(newArray);
        }
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
      });
  };
  let LedgerSum = [];
  const prefixSum = () => {
    let psum = 0;
    Ledger_Info.map(async (x) => {
      LedgerSum.push((psum = psum + (x.Debit - x.Credit)));

      if (x.SNo == Ledger_Info.length - 1) {
        await Set_Ledger_Total({
          T_Debit: Ledger_Info.reduce((a, b) => a + (b.Debit || 0), 0),
          T_Credit: Ledger_Info.reduce((a, b) => a + (b.Credit || 0), 0),
          T_Balance: LedgerSum.reduce((a, b) => a + (b || 0), 0),
        });
        renderLedger();
      }
    });
  };

  let [arrayLedgerItems, setarrayLedgerItems] = useState(null);
  const renderLedger = async () => {
    setarrayLedgerItems(
      await Ledger_Info.map((ledger) => {
        let html = (
          <tr>
            {/* ledger.Event == "1"?"M":"E" +  */}
            <td style={{ textAlign: "center", fontSize: "10pt" }}>
              {misc.formatDate(ledger.Trans_Date)}
            </td>
            <td
              style={{
                textAlign: "left",
                paddingRight: "5px",
                fontSize: "10pt",
              }}
            >
              {ledger.Event_ID == 1
                ? "Morning"
                : ledger.Event_ID == 2
                ? "Evening"
                : ledger.Event_ID == 3
                ? "Opening Balance"
                : "Receipt"}
            </td>
            <td
              style={{
                textAlign: "right",
                paddingRight: "5px",
                fontSize: "10pt",
              }}
            >
              {ledger.Debit ? misc.currencyWithOutDecimal(ledger.Debit) : "-"}
            </td>
            <td
              style={{
                textAlign: "right",
                paddingRight: "5px",
                fontSize: "10pt",
              }}
            >
              {ledger.Credit ? misc.currencyWithOutDecimal(ledger.Credit) : "-"}
            </td>
            <td
              style={{
                textAlign: "right",
                paddingRight: "5px",
                fontSize: "10pt",
              }}
            >
              {LedgerSum[ledger.SNo]}
            </td>
          </tr>
        );
        return html;
      })
    );
    console.log(arrayLedgerItems);
  };
  // // generating receipt table rows dynamically end
  useEffect(() => {
    if (Ledger_Info.length > 0) prefixSum();
  }, [Ledger_Info]);
  useEffect(() => {
    if (arrayLedgerItems != null) PrintLedgerReceipt();
  }, [arrayLedgerItems]);
  // print receipt function start
  const PrintLedgerReceipt = () => {
    var sOption = "width=1000,height=1000,left=100,top=25";
    var sWinHTML = document.getElementById("LEDGER-POS").innerHTML;
    var winprint = window.open("", "", sOption);
    winprint.document.open();
    winprint.document.write("<html><head><style>");
    winprint.document.write(`
           @media screen{
            @page{width:78mm;}
              
             /* customer_List -table */
             table{
               border-collapse: collapse;
               width: -webkit-fill-available;
             }
             /* customer_List - table rows and column  */
             td, th {
               padding: 2px;
               border: 1px solid black;
               text-align: left;
             
             }

             #pagebreak{ page-break-before: always; }
             .pagebreak{ page-break-before: always; }
             
           }
          
          
          @media print{
        
            
              
             table{
               border-collapse: collapse;
               width: -webkit-fill-available;
             }
             /* customer_List - table rows and column  */
             td, th {
               padding: 2px;
               border: 1px solid black;
               text-align: left;
             
             }
             #pagebreak{ page-break-before: always; }
             .pagebreak{ page-break-before: always; }
             
           }
          `);

    winprint.document.write(
      `</style></head><body style="margin:auto" onload="window.print()">`
    );
    winprint.document.write(sWinHTML);
    winprint.document.write("</body></html>");
    //    winprint.document.close();
    winprint.focus();
    winprint.print();
    //    if( !/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //     winprint.close();
    //    }
    return true;
  };
  // print receipt function end

  const openInPopupLedger = (Customer) => {
    Set_Ledger_Customer(Customer);
    Set_Open_Popup_Ledger_Duration(true);
  };

  // Ledger print code end
  // generte pdf code
  // const generatePdf = ()=> {
  // var doc = new jsPDF();
  // doc.autoTable({
  //     html: '#table',
  //     theme:'grid',
  //     textColor:22
  // });
  // doc.save('table.pdf');
  // };

  // generate pdf code
  const insertReceipt = (receipt) => {
    console.log("insert run");
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios.post("/api/addreceipt", receipt, { headers: headers }).then((res) => {
      setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? "error" : "success",
      });
      if (res.data.iserror === false) {
        props.EmptyAccountsBalances();
        setPage(0);
        props.getAllAccountsBalances(
          { page: 0, limit: rowsPerPage },
          setLoading
        );
      }
    });
  };

  const addOrEdit = (AccountsBalance, resetForm) => {
    insertReceipt(AccountsBalance);
    resetForm();
    setRecordForEdit(null);
    setOpenPopupReceipt(false);
    setOpenPopupOrder(false);
  };

  const openInPopupReceipt = (receipt) => {
    setRecordForEdit({ ...receipt, Trans_Customer_ID: receipt.Customer_ID });
    setOpenPopupReceipt(true);
  };

  // generating receipt table rows dynamically start
  let arrayItems;
  arrayItems = props.AccountsBalances_List.map((Balance) => {
    let html = (
      <tr>
        <td style={{ paddingLeft: "5px", fontSize: "8pt" }}>
          {Balance.Customer_Name}
          <br></br>
          {Balance.Customer_Mobile}
        </td>
        <td
          style={{ textAlign: "right", paddingRight: "5px", fontSize: "8pt" }}
        >
          {Balance.Balance}
        </td>
      </tr>
    );
    return html;
  });
  // generating receipt table rows dynamically end
  // print balance function start
  function PrintBalance() {
    var sOption =
      "toolbar=yes,location=no,directories=yes,menubar=yes,scrollbars=yes,width=1000,height=1000,left=100,top=25";
    var sWinHTML = document.getElementById("BALANCE-POS").innerHTML;
    var winprint = window.open("", "", sOption);
    winprint.document.open();
    winprint.document.write("<html><head><style>");
    winprint.document.write(`
       @media screen{
        @page{width:72mm;}
          
         /* customer_List -table */
         table{
           border-collapse: collapse;
           width: -webkit-fill-available;
         }
         /* customer_List - table rows and column  */
         td, th {
           padding: 2px;
           border: 1px solid brown;
           text-align: left;
         
         }
         
       }
      
      
      @media print{
    
        
          
         table{
           border-collapse: collapse;
           width: -webkit-fill-available;
         }
         /* customer_List - table rows and column  */
         td, th {
           padding: 2px;
           border: 1px solid brown;
           text-align: left;
         
         }
         
       }
      `);
    winprint.document.write('</style></head><body style="margin:auto" ">');
    winprint.document.write(sWinHTML);
    winprint.document.write("</body></html>");
    //    winprint.document.close();
    winprint.focus();
    winprint.print();
    //    if( !/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //     winprint.close();
    //    }
    return true;
  }
  // print balance function end

  return (
    <div className={classes.root}>
      <PageHeader
        title={
          props.accountBalanceLanguage.Account_Balance.Account_Balance_Header
            .Title
        }
        icon={<AccountBalance color="primary" fontSize="large" />}
        divWidthlg={6}
        divWidthmd={6}
        divWidthsm={4}
        titleWidthlg={6}
        titleWidthmd={6}
        titleWidthsm={4}
      >
        <Grid
          container
          spacing={1}
          className={classes.ToolbarItems}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Controls.Input
              label={
                props.accountBalanceLanguage.Account_Balance
                  .Account_Balance_Header.SearchInputTitle
              }
              width="100%"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <IconButton
              variant="outlined"
              className={classes.newButtonMobile}
              onClick={PrintBalance}
            >
              <Box borderRadius="50%" {...defaultProps}>
                <Print />
              </Box>
            </IconButton>
            <Controls.Button
              text={
                props.accountBalanceLanguage.Account_Balance
                  .Account_Balance_Header.Print_Button
              }
              width="100%"
              height="4rem"
              startIcon={<Print />}
              className={classes.newButton}
              onClick={PrintBalance}
            />
          </Grid>
        </Grid>
      </PageHeader>
      <Paper elevation={7} className={classes.pageContent}>
        <div
          className={`table-container ${
            localStorage.getItem("dodaily_urdu") == "true"
              ? "UrduFontTableHead"
              : ""
          }`}
          onScroll={handleScroll}
        >
          <TblContainer>
            <TblHead />
            <TableBody>
              {props.AccountsBalances_List !== null ? (
                recordsAfterPagingAndSorting().map(
                  (AccountsBalances_List_AccountsBalance) => (
                    <TableRow
                      key={AccountsBalances_List_AccountsBalance.Trans_ID}
                    >
                      <TableCell name="name" data-heading="Customer Name">
                        {AccountsBalances_List_AccountsBalance.Customer_Name}
                      </TableCell>
                      <TableCell name="mobile" data-heading="Customer Mobile">
                        {AccountsBalances_List_AccountsBalance.Customer_Mobile}
                      </TableCell>
                      <TableCell name="amount" data-heading="Bills">
                        {misc.currencyOnlyDecimal(
                          AccountsBalances_List_AccountsBalance.Debit
                        )}
                      </TableCell>
                      <TableCell name="amount" data-heading="Receipts">
                        {misc.currencyOnlyDecimal(
                          AccountsBalances_List_AccountsBalance.Credit
                        )}
                      </TableCell>
                      <TableCell name="amount" data-heading="Balance">
                        {misc.currencyOnlyDecimal(
                          AccountsBalances_List_AccountsBalance.Balance
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.ActionBtnDiv}
                        data-heading="Action"
                      >
                        <Controls.ActionButton
                          colorPrimary="colorPrimary"
                          onClick={() => {
                            openInPopupReceipt(
                              AccountsBalances_List_AccountsBalance
                            );
                          }}
                        >
                          <Receipt fontSize="small" />
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          colorPrimary="colorSecondary"
                          onClick={() =>
                            openInPopupLedger(
                              AccountsBalances_List_AccountsBalance
                            )
                          }
                        >
                          <Print fontSize="small" />
                        </Controls.ActionButton>
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <Controls.Loader />
              )}
              {page < props.totalPages ? (
                <Controls.Button
                  id="scroll_Btn"
                  width="100%"
                  height="4rem"
                  text="Load"
                  onClick={() =>
                    page < props.totalPages ? setPage(page + 1) : null
                  }
                ></Controls.Button>
              ) : null}
            </TableBody>
          </TblContainer>
          {loading ? <Controls.Loader /> : null}
          {!loading && props.AccountsBalances_List.length === 0 ? (
            <Typography
              variant="h4"
              style={{ margin: "auto", display: "block", textAlign: "center" }}
            >
              No Record
            </Typography>
          ) : null}
        </div>
        <table
          id="table"
          style={{
            width: " 100%",
            borderCollapse: "collapse",
            border: "1px solid black",
            display: "none",
          }}
        >
          <tr>
            {headCells.map((AccountsBalances_List_AccountsBalance, index) =>
              AccountsBalances_List_AccountsBalance.label !== "Actions" ? (
                <th key={index} style={{ border: "1px solid black" }}>
                  {AccountsBalances_List_AccountsBalance.label}
                </th>
              ) : null
            )}
          </tr>
          {props.AccountsBalances_List
            ? recordsAfterPagingAndSorting().map(
                (AccountsBalances_List_AccountsBalance, index) => (
                  <tr key={index}>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="name"
                      data-heading="Customer"
                    >
                      {misc.formatDate(
                        AccountsBalances_List_AccountsBalance.Customer_Name
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="mobile"
                      data-heading="Mobile"
                    >
                      {AccountsBalances_List_AccountsBalance.Customer_Mobile}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Bills"
                    >
                      {misc.currencyOnlyDecimal(
                        AccountsBalances_List_AccountsBalance.Debit
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Receipts"
                    >
                      {misc.currencyOnlyDecimal(
                        AccountsBalances_List_AccountsBalance.Credit
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Balance"
                    >
                      {misc.currencyOnlyDecimal(
                        AccountsBalances_List_AccountsBalance.Balance
                      )}
                    </td>
                  </tr>
                )
              )
            : null}
        </table>
      </Paper>
      <Popup
        title={"Select Duration"}
        openPopup={Open_Popup_Ledger_Duration}
        setOpenPopup={Set_Open_Popup_Ledger_Duration}
      >
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Controls.DateImputMaterialUi
              label="FROM"
              value={Ledger_Duration.Start_Date}
              onChange={(Start_Date) => {
                Set_Ledger_Duration({
                  ...Ledger_Duration,
                  Start_Date: misc.formatDateyearmonthday(Start_Date),
                });
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Controls.DateImputMaterialUi
              label="TO"
              value={Ledger_Duration.End_Date}
              onChange={(End_Date) => {
                Set_Ledger_Duration({
                  ...Ledger_Duration,
                  End_Date: misc.formatDateyearmonthday(End_Date),
                });
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Controls.Button
              onClick={() => getLedger(Ledger_Customer)}
              text={"Print"}
              width="100%"
              height="4rem"
            />
          </Grid>
        </Grid>
      </Popup>
      <Popup
        title={
          props.accountBalanceLanguage.Account_Balance.Account_Balance_Form
            .Title
        }
        openPopup={openPopupReceipt}
        setOpenPopup={setOpenPopupReceipt}
      >
        <ReceiptForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          formLanguage={
            props.accountBalanceLanguage.Account_Balance.Account_Balance_Form
          }
        />
      </Popup>
      <Popup
        title="Add Order"
        openPopup={openPopupOrder}
        setOpenPopup={setOpenPopupOrder}
      >
        <OrderForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* Account Print html Start */}
      <div
        id="BALANCE-POS"
        style={{
          width: " 100%",
          borderCollapse: "collapse",
          border: "1px solid black",
          display: "none",
        }}
      >
        <h2 style={{ textAlign: "center", marginBottom: "2pt" }}>
          {sessionStorage.getItem("usershop")}
        </h2>
        <div
          style={{
            height: "0px",
            backgroundColor: "black",
            color: "black",
            width: "100%",
            outline: "none",
            border: "1px solid black",
            display: "block",
          }}
        ></div>
        <div
          style={{
            width: "50%",
            display: "inline-block",
            float: "left",
            marginTop: "2pt",
          }}
          id="info"
        >
          <h5
            style={{
              fontSize: "8pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "left",
            }}
          >
            Balance List
          </h5>
        </div>
        <div
          style={{
            width: "50%",
            display: "inline-block",
            float: "right",
            marginTop: "2pt",
          }}
          id="info"
        >
          <h5
            style={{
              fontSize: "8pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "right",
            }}
          >
            Date:&nbsp;{`${misc.formatDate(new Date())}`}
            <br></br>
          </h5>
        </div>
        <div id="table">
          <table className="table-container__print_table">
            <tr>
              <th>Customer</th>
              <th>Amount </th>
            </tr>
            {arrayItems}
            <td style={{ fontWeight: "bold", fontSize: "8pt" }}>Total</td>
            <td style={{ textAlign: "right", fontSize: "8pt" }}>
              {props.AccountsBalances_List.reduce(
                (a, b) => a + (b.Balance || 0),
                0
              )}
            </td>
          </table>
        </div>
        <div
          style={{
            height: "0px",
            margin: "10px 0",
            backgroundColor: "black",
            color: "black",
            width: "100%",
            outline: "none",
            border: "1px solid black",
            display: "block",
          }}
        ></div>
        <div>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "center",
            }}
          >
            {sessionStorage.getItem("usercontactperson")}-
            {sessionStorage.getItem("usermobile")}
            <br></br>
            {sessionStorage.getItem("usershopaddress")}
          </p>
        </div>

        <div
          style={{
            height: "0px",
            backgroundColor: "black",
            color: "black",
            width: "100%",
            outline: "none",
            border: "1px solid black",
            display: "block",
          }}
        ></div>
        <div>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "center",
            }}
          >
            Powered By : DoDaily.pk
          </p>
        </div>
      </div>
      {/* Account Print html end */}

      {/* Ledger Print html Start */}
      <div
        id="LEDGER-POS"
        style={{
          width: " 100%",
          borderCollapse: "collapse",
          border: "1px solid black",
          display: "none",
        }}
      >
        <h2 style={{ textAlign: "center" }}>
          {sessionStorage.getItem("usershop")}
        </h2>
        <div
          style={{
            marginBottom: "4px",
            height: "0px",
            backgroundColor: "black",
            color: "black",
            width: "100%",
            outline: "none",
            border: "1px solid black",
            display: "block",
          }}
        ></div>
        <div
          style={{ width: "100%", display: "inline-block", float: "left" }}
          id="info"
        >
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "left",
            }}
          >
            Ledger Account: {Ledger_Customer.Customer_Name}
          </p>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "left",
            }}
          >
            {`From: ${misc.formatDate(
              Ledger_Duration.Start_Date
            )} - ${misc.formatDate(Ledger_Duration.End_Date)}`}
          </p>
          {/* <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "left",
            }}
          >
            Milk Rate: {Ledger_Customer.Customer_Milk_Rate}
          </p>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "left",
            }}
          >
            Yog Rate: {Ledger_Customer.Customer_Yog_Rate}
          </p> */}
        </div>
        {/* <div
          style={{ width: "50%", display: "inline-block", float: "right" }}
          id="info"
        > */}

        {/* <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left", width: "50%" }}>Bal :</span>{" "}
            <span style={{ float: "left", width: "50%" }}>
              {Ledger_Info != null
                ? misc.currencyWithOutDecimal(Ledger_Info[1].Balance)
                : 0}
            </span>
          </p>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left", width: "50%" }}>Rcv :</span>{" "}
            <span style={{ float: "left", width: "50%" }}>
              {Ledger_Info != null
                ? misc.currencyWithOutDecimal(Ledger_Info[1].Receipts)
                : 0}
            </span>
          </p>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left", width: "50%" }}> Opn :</span>{" "}
            <span style={{ float: "left", width: "50%" }}>
              {Ledger_Info != null
                ? misc.currencyWithOutDecimal(
                    Ledger_Info[1].Balance - Ledger_Info[1].Receipts
                  )
                : 0}
            </span>
          </p> */}
        {/* </div> */}

        <div id="table">
          <table className="table-container__print_table">
            <tr>
              <th style={{ fontSize: "10pt" }}>Date</th>
              <th style={{ fontSize: "10pt" }}>Event </th>
              <th style={{ fontSize: "10pt" }}>Debit </th>
              <th style={{ fontSize: "10pt" }}>Credit </th>
              <th style={{ fontSize: "10pt" }}>Balance</th>
            </tr>
            {arrayLedgerItems}
            <td style={{ fontWeight: "bold", fontSize: "10pt" }}>Total</td>
            <td style={{ textAlign: "right", fontSize: "10pt" }}></td>
            <td style={{ textAlign: "right", fontSize: "10pt" }}>
              {LedgerTotal.T_Debit != null
                ? misc.currencyWithOutDecimal(LedgerTotal.T_Debit)
                : null}
            </td>
            <td style={{ textAlign: "right", fontSize: "10pt" }}>
              {LedgerTotal.T_Credit != null
                ? misc.currencyWithOutDecimal(LedgerTotal.T_Credit)
                : null}
            </td>
            <td style={{ textAlign: "right", fontSize: "10pt" }}>
              {LedgerTotal.T_Balance != null
                ? misc.currencyWithOutDecimal(LedgerTotal.T_Balance)
                : null}
            </td>
          </table>
        </div>
        {/* <div
          style={{
            height: "0px",
            backgroundColor: "black",
            color: "black",
            width: "100%",
            outline: "none",
            border: "1px solid black",
            display: "block",
          }}
        ></div>
        <div>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "center",
            }}
          >
            {sessionStorage.getItem("usercontactperson")}-
            {sessionStorage.getItem("usermobile")}
            <br></br>
            {sessionStorage.getItem("usershopaddress")}L
          </p>
        </div>

        <div
          style={{
            height: "0px",
            backgroundColor: "black",
            color: "black",
            width: "100%",
            outline: "none",
            border: "1px solid black",
            display: "block",
          }}
        ></div> */}
        <div>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginTop: "4pt",
              textAlign: "center",
            }}
          >
            Powered By : DoDaily.pk
          </p>
          <div id="pagebreak" className="pagebreak"></div>
        </div>
      </div>
      {/* Ledger Print html end */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  accountBalanceLanguage: state.language.language,
  AccountsBalances_List: state.AccountsBalancesList.AccountsBalances_List,
  totalPages: state.AccountsBalancesList.totalPages,
  currentPage: state.AccountsBalancesList.currentPage,
  totalAccountsBalances: state.AccountsBalancesList.totalAccountsBalances,
});
const mapDispatchToProps = (dispatch) => ({
  getAllAccountsBalances: (data, setLoading) => {
    dispatch(GetAccountsBalances(data, setLoading));
  },
  EmptyAccountsBalances: () => {
    dispatch(EmptyAccountsBalances());
  },
  DeleteAccountsBalance: (
    accountsBalanceID,
    totalPages,
    totalAccountsBalances
  ) => {
    dispatch(
      DeleteAccountsBalance(
        accountsBalanceID,
        totalPages,
        totalAccountsBalances
      )
    );
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountsBalances);
