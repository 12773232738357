import {deleteReceived} from '../utils/ReceivedUtls'
const initilastate ={
    Receiveds_List:[],
    totalPages:"",
    currentPage:"",
    totalReceiveds:"",
    }

    const ReceivedsList =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "GETRECEIVEDS":
                console.log(Action.payload)
                return{
                    ...state,
                    Receiveds_List:[...state.Receiveds_List,...Action.payload.Receipts],
                    totalPages:Action.payload.totalPages,
                    currentPage:Action.payload.currentPage,
                    totalReceiveds:Action.payload.totalReceiveds,
                    }
            break;
            case "DELETERECEIVED":
                return{
                    ...state,
                    Receiveds_List:deleteReceived(state.Receiveds_List,Action.payload.receivedID),
                    totalReceiveds:Action.payload.totalReceiveds,
                    totalPages:Action.payload.totalPages,
                    }
            break;
            case "EMPTYRECEIVEDS":
                return{
                    ...state,
                    Receiveds_List: [],
                    totalPages:"",
                    currentPage:"",
                    totalReceiveds:"",
                    }
            break;
            default:
                return state
                break;
        }
    
    }
    
    export default ReceivedsList
    
    

