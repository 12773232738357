import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link,useHistory} from 'react-router-dom'
import SuccessfulModel from "../../components/Model"
import { Grid,Paper, Avatar, TextField, Button, Typography,FormControl,InputLabel,Input,InputAdornment,IconButton } from '@material-ui/core'
import {AccountBox,Visibility,VisibilityOff,Done} from '@material-ui/icons';
import TransitionAlerts from "../common/Alert"
import axios from 'axios'
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundSize:"cover",
    height:"100vh",
    background:`url(${process.env.PUBLIC_URL}/images/auth-bg-desktop.jpg)`,
    [theme.breakpoints.only('xl')]:{
      background:`url(${process.env.PUBLIC_URL}/images/auth-bg-bigdesktop.jpg)`,
      backgroundSize:"cover",
    },
    [theme.breakpoints.between('xs','sm')]:{
      background:`url(${process.env.PUBLIC_URL}/images/auth-bg-mobile.jpg)`,
      backgroundSize:"cover",
    }

  },
  imageGrid:{
    [theme.breakpoints.down('sm')]:{
      display:"none"
    },
    "& img":{
      width:"50rem",
      height:"50rem"
    }
  },
  inputField:{
    "& .MuiInputBase-root":{
      fontSize:"1.6rem"
    }
  },
  Alert:{
    margin:"2rem 0rem 2rem 10rem",
    [theme.breakpoints.down('sm')]:{
      margin:"0",
      width:"fit-content",
      position:"fixed",
      top:"70%",
      left:"50%",
      transform:"translate(-50%,-70%)"
    },
  },
  paperStyle:{
    padding :20,
    minHeight:"35rem",
    width:280,
     margin:"2rem 0rem 2rem 19rem",
    backgroundColor:"rgba(255,255,255,.9)",
    [theme.breakpoints.down('sm')]:{
      margin:"2rem auto 2rem auto",
      backgroundColor:"rgba(255,255,255,.5)"
    }
  },

  authLint:{
    color:theme.palette.primary.main
  },
  avatarStyle:{backgroundColor: theme.palette.primary.main},
  caption:{
    position: "absolute",
    top:"95%",
    left:"1%",
    fontSize:"1.6rem",
    "& a":{
      fontWeight:"bold"
    }
  }
}));

export default function Register(props) {
  const classes = useStyles();
  const [state, setState] = useState({ userName:"",shopName: "",phoneNumber:"", password: "",confirmPassword:"" });
  const [alert, setAlert] = useState({ isOpen: false, message: '', type: '' })
  const [openModel,setOpenModel]= useState({isopen:false,user:""})
  const [values, setValues] = useState({
   showPassword: false,
   showConfirmPassword:false
 });
  const history = useHistory()
   function onChange(event) {
     const { name, value } = event.target;
     setState(prevState => ({ ...prevState, [name]: value }));
   }
   
  const onRegister=  (event)=>{
     event.preventDefault()
     const headers = {
       'Content-Type': 'application/json',
     }
 
    const data= JSON.stringify(state)
     axios.post('/auth/register',data,{
       headers: headers
     }).then(res=>{
       if (res.data.iserror === false) {
         setState({...state ,userName:"",shopName: "",phoneNumber:"", password: "",confirmPassword:"" })
         setOpenModel({...openModel,isopen:true})
         setAlert({
           isOpen: false,
           message: res.data.message,
           type: res.data.iserror ? 'error':'success'
       })
       } else {
        
         setAlert({
           isOpen: true,
           message: res.data.message,
           type: res.data.iserror ? 'error':'success'
       })
       }
     }).catch(err=>{
       setAlert({
         isOpen: true,
         message: err.response.data.message,
         type: err.response.data.iserror ? 'error':'success'
     })
         console.log(err.response.data.message)
     })
 }
 
 
 const handleClickShowPassword = () => {
   setValues({ ...values, showPassword: !values.showPassword,showConfirmPassword: !values.showConfirmPassword });
 };
 
 const handleMouseDownPassword = (event) => {
   event.preventDefault();
 };
const btnstyle={margin:'8px 0'}
 return (
  <>
  <div className={classes.root}>
 <Grid container>
   <Grid item className={classes.Alert}  xs={8}  sm={6} lg={4}><TransitionAlerts message={alert.message} open={alert.isOpen} setOpen={()=>setAlert({...alert,isOpen:false})} type={alert.type} reopen={false}/>
   </Grid>
     </Grid>
     <Grid container lg={12} md={12} sm={12} xs={12}>
       <Grid item lg={6} md={6} sm={12} xs={12}>
     <Paper elevation={10} className={classes.paperStyle}>
     <Grid align='center'>
          <Avatar className={classes.avatarStyle}><AccountBox/></Avatar>
         <h2>Register</h2>
     </Grid>
     <TextField
     id="outlined-secondary"
     label="User Name"
     placeholder="User Name"
     className={classes.inputField}
     name="userName"
     value={state.userName}
     fullWidth
     required
     onChange={onChange}
   />
   <TextField
     id="outlined-secondary"
     label="Shop Name"
     placeholder="Shop Name"
     className={classes.inputField}
     name="shopName"
     value={state.shopName}
     fullWidth
     required
     onChange={onChange}
   />
   <TextField
   className={classes.inputField}
     id="outlined-secondary"
     label="Mobile Number"
     name="phoneNumber"
     placeholder="03000000000"
     value={state.phoneNumber}
     type="tel"
     fullWidth
     required
     onChange={onChange}
   />
   {/* <TextField
     id="outlined-secondary"
     label="Password"
     fullWidth
     required
     type="password"
     name="password"
     value={state.password}
     onChange={onChange}
   /> */}
   <FormControl className={classes.inputField}>
       <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
       <Input
       placeholder="##########"
         id="standard-adornment-password"
         type={values.showPassword ? 'text' : 'password'}
         value={state.password}
         name="password"
         onChange={onChange}
         endAdornment={
           <InputAdornment position="end">
             <IconButton
               aria-label="toggle password visibility"
               onClick={handleClickShowPassword}
               onMouseDown={handleMouseDownPassword}
             >
               {values.showPassword ? <Visibility /> : <VisibilityOff />}
             </IconButton>
           </InputAdornment>
         }
       />
     </FormControl>
   {/* <TextField
     id="outlined-secondary"
     label="confirm Password"
     fullWidth
     required
     type="password"
     name="confirmPassword"
     value={state.confirmPassword}
     onChange={onChange}
   /> */}
   <FormControl className={classes.inputField}>
       <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
       <Input
       placeholder="##########"
         id="standard-adornment-password"
         type={values.showPassword ? 'text' : 'password'}
         value={state.confirmPassword}
         name="confirmPassword"
         onChange={onChange}
         endAdornment={
           <InputAdornment position="end">
             <IconButton
               aria-label="toggle password visibility"
               onClick={handleClickShowPassword}
               onMouseDown={handleMouseDownPassword}
             >
               {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
             </IconButton>
           </InputAdornment>
         }
       />
     </FormControl>
     <Button onClick={onRegister} color='primary' variant="contained" style={btnstyle} fullWidth>Register</Button>
     {/* <Typography > Aready have an account ?
          <Link className={classes.authLint} to="/admin" >
             Sign In as Admin
     </Link>
     </Typography> */}
     <Typography > Aready have an account?
          <Link className={classes.authLint} to="/login" >
             &nbsp;Sign In
     </Link>
     </Typography>
 </Paper>
 </Grid>
  </Grid>
    </div>
    <div className={classes.caption}>Image by <a href="https://pixabay.com/users/anniespratt-4900708/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1209635" target="__blank">Annie Spratt</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1209635" target="__blank">Pixabay</a></div>
    <SuccessfulModel open={openModel} setOpen={setOpenModel} >
      <div id='card' class="animated fadeIn">
  <div id='upper-side'>
      <Done></Done>
      <h3 id='status'>
      Success
    </h3>
  </div>
  <div id='lower-side'>
    <p id='message'>
      Congratulations, your account has been successfully created.
    </p>
    <a href="/login" id="contBtn">Sign In</a>
  </div>
</div>
    </SuccessfulModel>
    </>
  );
}