import React, { useState,useRef, useEffect } from 'react'
import ReceivedForm from "./ReceivedForm";
import PageHeader from "../../components/PageHeader";
import axios from 'axios'
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Box,Grid, IconButton,Typography } from '@material-ui/core';
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import {connect} from 'react-redux'
import { Search,Receipt } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import FileCopy from '@material-ui/icons/FileCopy';
import Popup from "../../components/Popup";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Delete from '@material-ui/icons/Delete'; 
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import {GetReceiveds,EmptyReceiveds,DeleteReceived} from '../../redux/actions/ReceivedAction'
import jsPDF from 'jspdf';
import misc from '../../fnc/misc'
import 'jspdf-autotable';
const useStyles = makeStyles(theme => ({
    root:{
        "& .pageHeaderPageInfo":{
            [theme.breakpoints.down('sm')]:{
            display:"none !important"
            }
        },
    },

    pageContent: {
        padding: theme.spacing(3,3,0,3),
        height: `calc(${window.innerHeight}px - 200px)`,
        [theme.breakpoints.down('sm')]:{
            height: `calc(${window.innerHeight}px - 200px)`,
        },
        [theme.breakpoints.down('xs')]:{
            height: `calc(${window.innerHeight}px - 170px)`,
        },
        overflow: "hiddin",
        "& .MuiGrid-item > *":{
       
            maxWidth: `calc(100% - 1rem) !important`,
            width: `calc(100% - 1rem) !important`,
            marginRight: `1rem !important`
       },
       /// balance table css
       "& .table-container":{
        height: `calc(${window.innerHeight}px - 250px)`,
        overflow:"auto","& thead":{
            "&  tr" :{

                    "& [name=name]":{
                        minWidth:"10rem !important"
                    },
                    "& [name=detail]":{
                        minWidth:"20rem !important",
                       
                    },
                    "& [name=amount]":{
                        minWidth:"10rem !important"
                    },
                    "& [name=actions]":{
                        minWidth:"10rem !important"
                    },
                    [theme.breakpoints.down(900)]:{
                        "& [name=name]":{
                            minWidth:"auto !important"
                        },
                        "& [name=detail]":{
                            minWidth:"auto !important"
                           
                        },
                        "& [name=amount]":{
                            minWidth:"auto !important"
                        },
                        "& [name=actions]":{
                            minWidth:"auto !important"
                        },
                    }

              }
            
        },
        "& tbody":{

                "& tr":{
               
                    "& [name=name]":{
                        minWidth:"10rem !important"
                    },
                    "& [name=detail]":{
                        minWidth:"20rem !important",
                       
                    },
                    "& [name=amount]":{
                        minWidth:"10rem !important"
                    },
                    "& [name=actions]":{
                        minWidth:"10rem !important"
                    },
                [theme.breakpoints.down(900)]:{
                    "& [name=name]":{
                        minWidth:"auto !important"
                    },
                    "& [name=detail]":{
                        minWidth:"auto !important",
                        height: "1.9rem"
                       
                    },
                    "& [name=amount]":{
                        minWidth:"auto !important"
                    },
                    "& [name=actions]":{
                        minWidth:"auto !important",
                    },
                }
                },

            

        },
      
        "& #scroll_Btn":{
            margin:"auto",display:"none",
            [theme.breakpoints.between("xs","sm")]:{
                display:"block"
            }
        }
    }
    },
    Toolbar:{
    },
    
    ToolbarItems:{
        display:"flex",
        alignItems:"center",
        [theme.breakpoints.between("xs","sm")]:{
            justifyContent:"space-between"
        }

    },
   
    buttonContainerGrid:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.between("xs","sm")]:{
            marginRight:"2rem",
        },
        "& button":{
           
            width:"32.333%",
            [theme.breakpoints.between("md","xl")]:{
                marginRight:".3rem",
            },
        },

 
  },
    newButtonMobile:{
        padding:0,
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"flex"
          },
          [theme.breakpoints.up('md')]: {
            display:"none"
          },
    },
    newButton:{
        height: "4rem",
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"none"
          },
          [theme.breakpoints.up('md')]: {
            display:"flex"
          },
    },
    ActionBtnDiv:{
        [theme.breakpoints.down(900)]:{
            // display:"flex !important",
            "& > *":{
                width:"4rem",
                height:"3rem",
                padding:".5rem"
            },
            "& svg":{
                fontSize:"1.6rem"
            }
        }
    }
}))

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'inherit',
    m: 1,
    padding:0,
    border: 1,
    style: { width: '4rem', height: '4rem' },
    display:"flex",
    margin:"0rem",
    alignItems:"center",
    justifyContent:"center",
    
  };
  
  const Receipts = (props)=> {
      const headCells = props.receiptLanguage.Receipts.Receipts_Table.Receipts_Table_Head 
      
    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [filterFn, setFilterFn] = useState({ fn: receiveds => { return receiveds; } })
    const [openPopup, setOpenPopup] = useState(false)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const componentRef = useRef();
    const [loading, setLoading] = useState(false)
    const [searchReceivedName,setSearchReceivedName] =useState(null)
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        rowsPerPage,
        page,
        setPage,
        handleScroll,
        handleSortRequest,
        setOrder
    } = useTable(props.Receiveds_List, headCells, filterFn,componentRef,'medium',props.totalPages,props.currentPage,props.totalReceiveds,props.getAllReceiveds,props.EmptyReceiveds,true,setLoading);
    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: receiveds => {
                if (target.value == "")
                    return receiveds; 
                else
                    return receiveds.filter(x => `${x.Customer_Name}${misc.formatDate(x.Trans_Date).toString()}`.toLowerCase().includes(target.value) || `${x.Customer_Name}${misc.formatDate(x.Trans_Date).toString()}`.toUpperCase().includes(target.value)||  misc.capitalize(`${x.Customer_Name}${misc.formatDate(x.Trans_Date).toString()}`).includes(target.value))
            }
        })
    }

   
// generte pdf code
const generatePdf = ()=> {
var doc = new jsPDF();
doc.autoTable({
    html: '#ReceiptTable',
    theme:'grid',
    textColor:22
});
doc.save('table.pdf');
};


// generate pdf code
    const insertReceived = (receipt)=>{
        console.log('insert run')
        const headers={
          "x-access-token":sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/addreceipt',receipt,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    if(res.data.iserror === false){
    props.EmptyReceiveds()
    setPage(0)
    props.getAllReceiveds({page:0,limit:rowsPerPage},setLoading)
    }
      }
      )
    }
    const updateReceived = (receipt)=>{
        console.log('update run')
        console.log(receipt)
        const headers={
          "x-access-token":sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/updatereceipt',receipt,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    if(res.data.iserror === false){
        props.EmptyReceiveds()
        setPage(0)
        props.getAllReceiveds({page:0,limit:rowsPerPage},setLoading)
        }
     })
    }
    const addOrEdit = (Received, resetForm) => {
  
        if (Received.Trans_ID == null || Received.Trans_ID == undefined || Received.Trans_ID == '') {
          
           insertReceived(Received)
        }
        else if( Received.Trans_ID  ){
            updateReceived(Received)
        }
        resetForm()
        setRecordForEdit(null)
        setOpenPopup(false)
    }


    const openInPopup = receipt => {
        setRecordForEdit(receipt)
        setOpenPopup(true)
    }
   const deleteReceived=(receivedID)=>{
    const headers={
      "x-access-token":sessionStorage.getItem("accountingauthkey")
  }
  console.log('delete run',receivedID)
 axios.post('/api/deletereceipt',{Trans_ID:receivedID},{headers:headers}).then(res=>{
     setNotify({
    isOpen: true,
    message: res.data.message,
    type: res.data.iserror ? 'error':'success'
})
if(res.data.iserror === false){
    props.EmptyReceiveds()
    setPage(0)
    props.getAllReceiveds({page:0,limit:rowsPerPage},setLoading)
    }
 })
    }
    const changeSearchState = (e) => {
        const searchReceivedNameValue = e.target.value
      if (searchReceivedNameValue !== '') {
        setSearchReceivedName(searchReceivedNameValue)
      } else {
           setSearchReceivedName(null)
      }
        
    }

    const onDeleteReceived = (Trans_ID) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       deleteReceived(Trans_ID)
    }
    useEffect(()=>{
        handleSortRequest("Trans_ID")
    },[])
    return (
        <div className={classes.root}>
            <PageHeader 
                title={props.receiptLanguage.Receipts.Receipts_Header.Title}
                icon={<Receipt color="primary" fontSize="large" />}
            >
            <Grid container spacing={1} className={classes.ToolbarItems} lg={8} md={9} sm={12} xs={12} >
                <Grid item lg={5} md={5} sm={6} xs={6}   >
                    <Controls.Input
                        label={props.receiptLanguage.Receipts.Receipts_Header.SearchInputTitle}
                        width="100%"
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                    </Grid>
                    <Grid  spacing={1} container lg={7} md={7} sm={4} xs={5} >
                    <Grid   item lg={4} md={4} sm={4} xs={4} >
                    <IconButton
                        variant="outlined"
                        className={classes.newButtonMobile}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    ><Box borderRadius="50%" {...defaultProps}><AddIcon /></Box></IconButton>
                    <Controls.Button
                        text={props.receiptLanguage.Receipts.Receipts_Header.Add_Button}
                        width="100%"
                        height="4rem"
                        startIcon={<AddIcon />}
                        className={classes.newButton}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    />
                </Grid>
                <Grid   item lg={4} md={4} sm={4} xs={4} >
                    <IconButton
                        className={classes.newButtonMobile}
                        onClick={generatePdf}
                    ><Box borderRadius="50%" {...defaultProps}><Print /></Box></IconButton>
                    <Controls.Button
                        text={props.receiptLanguage.Receipts.Receipts_Header.Print_Button}
                        width="100%"
                        height="4rem"
                        startIcon={<Print/>}
                        className={classes.newButton}
                        onClick={generatePdf}
                    />
                    </Grid>
                    <Grid   item lg={4} md={4} sm={4} xs={4} >
                    <IconButton
                        
                        className={classes.newButtonMobile}
                        onClick={()=>misc.export_table_to_csv('#ReceiptTable',"Receipts.csv")}
                    ><Box borderRadius="50%" {...defaultProps}><FileCopy /></Box></IconButton>
                    <Controls.Button
                        text={props.receiptLanguage.Receipts.Receipts_Header.Excel_Button}
                        width="100%"
                       height="4rem"
                        startIcon={<FileCopy/>}
                        className={classes.newButton}
                        onClick={()=>misc.export_table_to_csv('#ReceiptTable',"Receipts.csv")}
                    />
                    </Grid>
                    </Grid>
                </Grid>
            </PageHeader>
            <Paper elevation={7} className={classes.pageContent}>
            <div className={`table-container ${localStorage.getItem('dodaily_urdu') == "true"?"UrduFontTableHead":""}`} onScroll={handleScroll}>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {props.Receiveds_List !== null?(
                            recordsAfterPagingAndSorting().map(Receiveds_List_Received =>
                                (<TableRow key={Receiveds_List_Received.Trans_ID} >
                                    <TableCell name="date"  data-heading='Date' >{misc.formatDate(Receiveds_List_Received.Trans_Date)}</TableCell>
                                    <TableCell name="name"  data-heading='Customer' >{Receiveds_List_Received.Customer_Name}</TableCell>
                                    <TableCell name="detail"  data-heading='Detail' >{Receiveds_List_Received.Trans_Detail}</TableCell>
                                    <TableCell name="amount"   data-heading='Amount' >{misc.currencyOnlyDecimal(Receiveds_List_Received.Credit_Amount)}</TableCell>
                                    <TableCell name="actions" className={classes.ActionBtnDiv}  data-heading='Action'>
                                        <>
                                        <Controls.ActionButton
                                            colorPrimary="colorPrimary"
                                            onClick={() => { openInPopup(Receiveds_List_Received) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                        colorPrimary="colorSecondary"
                                            onClick={() => {
                                                setConfirmDialog({
                                                isOpen: true,
                                                title: 'Are you sure to delete ?',
                                                subTitle: "You can't undo this operation",
                                                onConfirm: () => { onDeleteReceived(Receiveds_List_Received.Trans_ID) }
                                                })
                                                }}>
                                            <Delete fontSize="small" />
                                        </Controls.ActionButton></>
                                    </TableCell>
                                </TableRow>)
                            )):(<Controls.Loader/>)
                        }
                        {page < props.totalPages ?<Controls.Button id="scroll_Btn"  width="100%" height="4rem" text="Load" onClick={()=>page < props.totalPages ? setPage(page+1):null}></Controls.Button>:null}
                    </TableBody>
                    
                </TblContainer>
                {loading?<Controls.Loader/>:null}
                {!loading && props.Receiveds_List.length === 0 ?<Typography variant="h4" style={{margin:"auto",display:"block",textAlign:"center"}} >No Record</Typography>:null}             
                </div>
<table id="ReceiptTable" style={{width:" 100%",borderCollapse: "collapse",border: "1px solid black",display:"none"}} >
  <tr>
    {headCells.map((Receiveds_List_Received,index)=> Receiveds_List_Received.label !== "Actions"?<th key={index} style={{border: "1px solid black"}}>{Receiveds_List_Received.label}</th>:null)}
  </tr>
  {props.Receiveds_List?recordsAfterPagingAndSorting().map((Receiveds_List_Received,index)=>(
  <tr key={index}>
    <td style={{border: "1px solid black",color:"black"}}  name="date"  data-heading='Date' >{misc.formatDate(Receiveds_List_Received.Trans_Date)}</td>
    <td style={{border: "1px solid black",color:"black"}}  name="name"  data-heading='Customer' >{Receiveds_List_Received.Customer_Name}</td>
    <td style={{border: "1px solid black",color:"black"}}  name="detail"  data-heading='Detail' >{Receiveds_List_Received.Trans_Detail}</td>
    <td style={{border: "1px solid black",color:"black"}}  name="amount"   data-heading='Amount' >{Receiveds_List_Received.Credit_Amount}</td>
</tr>)):null}
</table>
            
            </Paper>
            <Popup
                title={props.receiptLanguage.Receipts.Receipts_Form.title}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <ReceivedForm
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit}
                    formLanguage={props.receiptLanguage.Receipts.Receipts_Form} />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        
        </div>
    )
}
const mapStateToProps=state=>({ 
    receiptLanguage: state.language.language,
    Receiveds_List: state.ReceivedsList.Receiveds_List,
    totalPages:state.ReceivedsList.totalPages,
    currentPage:state.ReceivedsList.currentPage,
    totalReceiveds:state.ReceivedsList.totalReceiveds
  })
  const mapDispatchToProps = dispatch=>({
    getAllReceiveds : (data,setLoading)=>{dispatch(GetReceiveds(data,setLoading))},
    EmptyReceiveds:()=>{dispatch(EmptyReceiveds())},
    DeleteReceived:(receivedID,totalPages,totalReceiveds)=>{dispatch(DeleteReceived(receivedID,totalPages,totalReceiveds))}
  })
  export default connect(mapStateToProps,mapDispatchToProps)(Receipts)

