import React, { useEffect,useState } from 'react'
import Axios from "axios"
import { Grid,InputAdornment,makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import ConfirmDialog from "../../components/ConfirmDialog";
import misc from "../../fnc/misc"
import $ from "jquery"


const useStyles = makeStyles(theme => ({


    
    grid6:{
        display:"flex",
        justifyContent:"space-between",
        width: "15rem",
        margin:".8rem .0rem 10rem .8rem",
        
    }
    

}))

export default function ReceivedForm(props) {
    const [initialFValues,setInitialFValues] =useState ({
        Trans_Customer_ID: null,
        Customer_Name: "",
        Trans_Date: new Date(),
        Trans_Detail:"",
        Credit_Amount: 0,
    })
    $(document).ready(function () {
        $("input").attr("autocomplete","off");
            });
    
    const { addOrEdit, recordForEdit, formLanguage } = props
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const classes = useStyles()
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('Trans_Customer_ID' in fieldValues)
            temp.Trans_Customer_ID = fieldValues.Trans_Customer_ID ? "" : "Customer Name is not valid"
        if ('Credit_Amount' in fieldValues)
            temp.Credit_Amount = fieldValues.Credit_Amount ? "" : "Ammount is not valid"
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleInputNunberChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {

        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null){
            setValues({
                ...recordForEdit
            })
            setInitialFValues({
                ...initialFValues,Trans_Customer_ID:recordForEdit.Trans_Customer_ID,Customer_Name:recordForEdit.Customer_Name,Credit_Amount:recordForEdit.Balance
            })
        }
        console.log(formLanguage)
    }, [recordForEdit])

    const onAddOrUdpateReceived = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        handleSubmit()
    }
    
    const onResetForm = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       resetForm()
    }
    // customer fucctionality start //
    const [searchCustomerName,setSearchCustomerName] =useState(null)
    const [customer_List,set_Customer_List] =useState([])

    const getCustomers = () => {

        const data = {
            searchCustomerName:searchCustomerName,
        }
        const headers={
            "x-access-token": sessionStorage.getItem("accountingauthkey")
        }
        Axios.post("/api/getordercustomers",data,{headers})
			.then((res) =>{set_Customer_List(res.data.result)
            })
        .catch((err) => {
            console.log("ERROR: ====", err);
          })   
    }

    const changeSearchCustomerState = (e) => { 
        const searchCustomerNameValue = e.target.value
      if (searchCustomerNameValue !== '') {
        setSearchCustomerName(searchCustomerNameValue)
        setInitialFValues({...initialFValues,Customer_Name:searchCustomerNameValue})
      } else {
           setSearchCustomerName(null)
           setInitialFValues({...initialFValues,Customer_Name:'',Trans_Customer_ID:null})
      }
    }

    let setCustomerList = (customer)=>{
        setInitialFValues({ ...initialFValues,Customer_Name:customer.Customer_Name,Trans_Customer_ID:customer.Customer_ID})
        setSearchCustomerName(null)
    }

    setCustomerList =setCustomerList.bind(this)
    useEffect(()=>{
        getCustomers()
    },[searchCustomerName])
    useEffect(()=>{
        console.log(initialFValues)
        setValues(initialFValues)
    },[initialFValues])
    // customer fucctionality end //
    /// date change //
    const [Received_Date,set_Received_Date]=useState(new Date())
    const handleDateChange=(Date)=>{
        set_Received_Date(Date)
        setInitialFValues({...initialFValues,Trans_Date:Date})
    }
    return (
        <>
        <Form onSubmit={(e) => {
            e.preventDefault()
            setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to Submit',
                subTitle: "You can't undo this operation",
                onConfirm: () => { onAddOrUdpateReceived() }
                            })
                }}>
            <Grid container spacing={1} lg={12} md={12} sm={12}>
                <Grid  item lg={12} md={12} sm={12} xs={12}>
                <div className='form_search_customer' >
                            <Controls.SearchList Input_ID="input_customer_name" ItemName="Customer_Name"  form_Name="customer" items={customer_List} setSelected={setCustomerList} >
                                <Controls.Input
                                    label={formLanguage.Input_Cutomer_Name}
                                    // width="100%"
                                    // marginLeft="2rem"
                                    disabled="true"
                                    name="Customer_Name"
                                    id="input_customer_name"
                                    nextid="input_MMQ"
                                    value={initialFValues.Customer_Name}
                                    onChange={changeSearchCustomerState}
                                        placeholder="Select Customer"
                                        aria-activedescenda
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>)
                                        }}
                                    ></Controls.Input>
                                            </Controls.SearchList>
                                           
                                </div>
                                </Grid>
                    <Grid  item lg={6} md={6} sm={6} xs={12}>
                    <Controls.DateInput
                        name="Trans_Date"
                        label={formLanguage.Input_Date}
                        value={values.Trans_Date}
                        onChange={(Received_Date)=>handleDateChange(Received_Date)}
                        error={errors.Trans_Date}
                    />
                    </Grid>
                    <Grid  item lg={6} md={6} sm={6} xs={12}>
                    <Controls.InputDecimalCustom
                        placeholder="0.00"
                        currencySymbol=""
                        name="Credit_Amount"
                        label={formLanguage.Input_Amount}
                        varient="amount"
                        value={values.Credit_Amount}
                        onChange={handleInputChange}
                        error={errors.Credit_Amount}
                    />
                    </Grid>
                                <Grid item xs={12}>
                                <Controls.Input
                                    name="Trans_Detail"
                                    label={formLanguage.Input_Detail}
                                    value={values.Trans_Detail}
                                    onChange={handleInputChange}
                                    error={errors.Trans_Detail}
                                />
                                </Grid>
                    <div className={classes.grid6}>
                        <Controls.Button
                        keyupfire={false}
                            type="submit"
                            text={formLanguage.Button_Save} />
                        
                    </div>
            
            </Grid>
        </Form>
          <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
      />
      </>
    )
}
