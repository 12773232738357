import React, { useState,memo, useEffect } from 'react'
import { Paper, makeStyles, Grid,Typography } from '@material-ui/core'
import Controls from "../components/controls/Controls"
import ConfirmDialog from "../components/ConfirmDialog"
import {Warning} from "@material-ui/icons"
import {connect} from 'react-redux'
import Notification from "../components/Notification"
import axios from 'axios';
import $ from "jquery"


const useStyles = makeStyles(theme => ({
    pageContent: {
        padding: theme.spacing(3,3,3,3),
        height: `calc(${window.innerHeight}px - 100px)`,
        [theme.breakpoints.up('md')]:{
            height: `calc(${window.innerHeight}px - 100px)`,
        },
        overflow: "hiddin",
    //     "& .MuiGrid-item > *":{
    //         maxWidth: `calc(100% - 1rem) !important`,
    //         width: `calc(100% - 1rem) !important`,
    //         marginRight: `1rem !important`
    //    },

    },
    "$ .makeStyles-root-48":{
        width: "4rem"
    },
    detailsWrapper:{
        "& > *":{
            marginBottom:"3rem",
            marginRight:".5rem"
        },
        "& .MuiTypography-h4":{
            display:"flex",
            alignItems:"center",
            [theme.breakpoints.down('sm')]:{
                fontSize:"1.2rem"
            },
            "& button":{
                width:"min-content"
            }
        },
        "& .MuiFormControl-root":{
            width:"100%",
            marginRight:".5rem"
        },
        "& .MuiFormLabel-root":{
            fontSize:"1.6rem"
        }
    }
 
}))


const UserProfile = (props)=> {

    const classes = useStyles();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [edits,setEdits]=useState({username:false,shopname:false,Address:false,contactperson:false})
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const [state,setState]=useState({username:null,shopname:null,Address:null,contactperson:null,phoneNumber:null})

    const changeState = (e)=>{
        const name = e.target.name
        const value = e.target.value
        setState({...state,[name]: value})
    }
    const getuserDetails =()=>{
        const options = {
            headers: {
                'Content-Type': 'application/json',
                "x-access-token":sessionStorage.getItem("accountingauthkey")
            }
          };
          axios.post('/api/getuserdetails',{},options).then(res=>{
    console.log(res.data)
      if(res.data.iserror){ 
          setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    }else{
        setState({...state,username:res.data.user.userName,shopname:res.data.user.ShopName,phoneNumber:res.data.user.phoneNumber,Address:res.data.user.Address,contactperson:res.data.user.contactPerson})
    }

       }).catch(err=>{
        setNotify({
            isOpen: true,
            message: err.response.data.message,
            type: err.response.data.iserror ? 'error':'success'
        })
       })
      }

      const updateuserDetails =()=>{
        const options = {
            headers: {
                'Content-Type': 'application/json',
                "x-access-token":sessionStorage.getItem("accountingauthkey")
            }
          };
          axios.post('/api/updateuserdetails',{username:state.username,shopname:state.shopname,Address:state.Address,contactperson:state.contactperson,phoneNumber:state.phoneNumber},options).then(res=>{
    console.log(res)
setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    getuserDetails()
    setEdits({username:false,shopname:false,Address:false,contactperson:false})
       }).catch((error) => {
        setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: error.response.data.iserror ? 'error':'success'
        })

      })
      }

      const onUpdate = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        updateuserDetails();
    }
    

    useEffect(()=>{
        getuserDetails()
    },[])
    useEffect(()=>{
        console.log(state)
    },[state])
    const editable = async (name,value)=>{
       await setEdits({...edits,[name]:value})
      await  $(`#${name}`).focus()
    }
    return (
        <>
            <Grid container className={classes.detailsWrapper} lg={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h4" ><Controls.Input label={props.AccountProfileLanguage.Account_Profile.Input_User_Name}  name="userName" disabled={true} value={state.username} ></Controls.Input></Typography>
                    {/* {edits.username?<Typography variant="h4" >User Name:&nbsp;<Controls.Input name="username" onChange={changeState} value={state.username} ></Controls.Input><Controls.Button onClick={()=>setEdits({...edits,username:false})}  color="danger" >cancel</Controls.Button></Typography>:<Typography variant="h4" >User Name:&nbsp;{state.username}<Controls.ActionButton onClick={()=>setEdits({...edits,username:true})}  color="primary" >Edit</Controls.ActionButton></Typography>} */}
                </Grid>
                <Grid item lg={12} md={12}  sm={12} xs={12}>
                {edits.shopname?<Typography variant="h4" ><Controls.Input label={props.AccountProfileLanguage.Account_Profile.Input_Shop_Name}  name="shopname" onChange={changeState} id="shopname" value={state.shopname} ></Controls.Input><Controls.Button  height="4rem" text="Cancel" onClick={()=>setEdits({...edits,shopname:false})}  color="danger" ></Controls.Button></Typography>:<Typography variant="h4" ><Controls.Input label={props.AccountProfileLanguage.Account_Profile.Input_Shop_Name}  name="shopname" disabled={true} value={state.shopname} ></Controls.Input><Controls.Button text="Edit" height="4rem" onClick={()=>editable("shopname",true)}  color="primary" >Edit</Controls.Button></Typography>}
                </Grid>
                <Grid item lg={12} md={12}  sm={12} xs={12}>
                     {edits.Address?<Typography variant="h4" ><Controls.Input label={props.AccountProfileLanguage.Account_Profile.Input_Address}  name="Address" onChange={changeState} id="Address" value={state.Address} ></Controls.Input><Controls.Button height="4rem" text="Cancel" onClick={()=>setEdits({...edits,Address:false})}  color="danger" ></Controls.Button></Typography>:<Typography variant="h4" ><Controls.Input label={props.AccountProfileLanguage.Account_Profile.Input_Address}  name="Address" disabled={true} value={state.Address} ></Controls.Input><Controls.Button text="Edit" height="4rem" onClick={()=>editable("Address",true)}  color="primary" >Edit</Controls.Button></Typography>}
                </Grid>
                <Grid item lg={12} md={12}  sm={12} xs={12}>
                     {edits.contactperson?<Typography variant="h4" ><Controls.Input label={props.AccountProfileLanguage.Account_Profile.Input_Contact_Person} name="contactperson" onChange={changeState} id="contactperson" value={state.contactperson} ></Controls.Input><Controls.Button height="4rem" text="Cancel" onClick={()=>setEdits({...edits,contactperson:false})}  color="danger" ></Controls.Button></Typography>:<Typography variant="h4" ><Controls.Input label={props.AccountProfileLanguage.Account_Profile.Input_Contact_Person}  name="contactperson" disabled={true} value={state.contactperson} ></Controls.Input><Controls.Button height="4rem" text="Edit" onClick={()=>editable("contactperson",true)}  color="primary" >Edit</Controls.Button></Typography>}
                </Grid>
                <Grid item lg={12} md={12}  sm={12} xs={12}>
                     <Typography variant="h4" ><Controls.Input label={props.AccountProfileLanguage.Account_Profile.Input_Phone_Number}  name="PhoneNumber" disabled={true} value={state.phoneNumber} ></Controls.Input></Typography>
                </Grid>
               
            </Grid>
            <Grid><Controls.Button onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to update profile ?',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onUpdate() }
                            })
                        }} 
                        text={props.AccountProfileLanguage.Account_Profile.Button_Update} height="4rem" ></Controls.Button></Grid>
   
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                ConfirmDialogIcon={<Warning/>}
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    AccountProfileLanguage: state.language.language,
})

  const mapDispatchToProps = dispatch=>({

  })
  export default connect(mapStateToProps,mapDispatchToProps)(memo(UserProfile))