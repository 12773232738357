import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CustomerForm from "./CustomerForm";
import PageHeader from "../../components/PageHeader";
import axios from "axios";
import AccountBox from "@material-ui/icons/AccountBox";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Box,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import { connect } from "react-redux";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import Print from "@material-ui/icons/Print";

import Popup from "../../components/Popup";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Delete from "@material-ui/icons/Delete";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import {
  GetCustomers,
  EmptyCustomer,
  DeleteCustomer,
  SetCustomers,
} from "../../redux/actions/CustomerAction";
import { changeLanguage } from "../../redux/actions/languageAction";

import misc from "../../fnc/misc";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .pageHeaderPageInfo": {
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
  },

  // balance page css
  pageContent: {
    padding: "1rem 1rem 1rem 1rem",
    height: `calc(100vh - 18rem)`,
    [theme.breakpoints.down("md")]: {
      height: `calc(100vh - 18rem)`,
    },
    [theme.breakpoints.down("sm")]: {
      height: `calc(100vh - 20rem)`,
    },
    [theme.breakpoints.down("xs")]: {
      height: `calc(100vh - 23rem)`,
    },
    overflow: "hiddin",
    "& .MuiGrid-item > *": {
      maxWidth: `calc(100% - 1rem) !important`,
      width: `calc(100% - 1rem) !important`,
      marginRight: `1rem !important`,
    },
    "& .table-container": {
      height: `100%`,
      overflow: "auto",
      "& thead": {
        "&  tr": {
          "& [name=date]": {
            minWidth: "10rem !important",
          },
          "& [name=name]": {
            minWidth: "15rem !important",
          },

          "& [name=mobile]": {
            minWidth: "5rem !important",
          },

          "& [name=rate]": {
            minWidth: "5rem !important",
          },
          "& [name=qty]": {
            minWidth: "3rem !important",
          },
          "& [name=active]": {
            minWidth: "3rem !important",
          },

          "& [name=actions]": {
            minWidth: "10rem !important",
          },
          [theme.breakpoints.down(900)]: {
            "& [name=date]": {
              minWidth: "auto !important",
            },
            "& [name=name]": {
              minWidth: "auto !important",
            },
            "& [name=mobile]": {
              minWidth: "auto !important",
            },

            "& [name=rate]": {
              minWidth: "auto !important",
            },
            "& [name=qty]": {
              minWidth: "auto !important",
            },
            "& [name=active]": {
              minWidth: "auto !important",
            },
            "& [name=actions]": {
              minWidth: "auto !important",
            },
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            [theme.breakpoints.down(900)]: {
              paddingLeft: "20rem",
              // "& p":{
              //     display:"inline",
              //     width:"50%"
              // }
            },
            [theme.breakpoints.down(539)]: {
              paddingLeft: "15rem",
            },
          },
          "& [name=date]": {
            minWidth: "10rem !important",
          },
          "& [name=name]": {
            minWidth: "15rem !important",
          },

          "& [name=mobile]": {
            minWidth: "5rem !important",
          },

          "& [name=rate]": {
            minWidth: "5rem !important",
          },
          "& [name=qty]": {
            minWidth: "3rem !important",
          },
          "& [name=active]": {
            minWidth: "3rem !important",
          },

          "& [name=actions]": {
            minWidth: "10rem !important",
          },
          [theme.breakpoints.down(900)]: {
            "& [name=date]": {
              minWidth: "auto !important",
            },
            "& [name=name]": {
              minWidth: "auto !important",
            },
            "& [name=mobile]": {
              minWidth: "auto !important",
            },

            "& [name=rate]": {
              minWidth: "auto !important",
            },
            "& [name=qty]": {
              minWidth: "auto !important",
            },
            "& [name=active]": {
              minWidth: "auto !important",
            },
            "& [name=actions]": {
              minWidth: "auto !important",
            },
          },
        },
      },

      "& #scroll_Btn": {
        margin: "auto",
        display: "none",
        [theme.breakpoints.between("xs", "sm")]: {
          display: "block",
        },
      },
    },
  },
  Toolbar: {},

  ToolbarItems: {
    display: "flex",
    alignItems: "center",
    // [theme.breakpoints.between("xs","sm")]:{
    //     paddingLeft:"1rem"
    // },
  },

  //     buttonContainerGrid:{
  //         display:"flex",
  //         alignItems:"center",
  //         justifyContent:"flex-end",
  //         [theme.breakpoints.between("xs","sm")]:{
  //             marginRight:"2rem",
  //         },
  //         "& button":{
  //             width:"49%",
  //             [theme.breakpoints.between("md","xl")]:{
  //                 marginRight:".3rem",
  //             },
  //         },

  //   },
  newButtonMobile: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  newButton: {
    height: "4rem",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  ActionBtnDiv: {
    [theme.breakpoints.down(900)]: {
      // display:"flex !important",
      // flexDirection:"column"
      "& > *": {
        width: "4rem",
        height: "3rem",
        padding: ".5rem",
      },
      "& svg": {
        fontSize: "1.6rem",
      },
    },
  },
}));

const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "inherit",
  m: 1,
  padding: 0,
  border: 1,
  style: { width: "4rem", height: "4rem" },
  display: "flex",
  margin: "0rem",
  alignItems: "center",
  justifyContent: "center",
};
const Customers = (props) => {
  let headCells =
    props.customerLanguage.Customer.Customer_Table.Customer_Table_Head;

  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [Billing_Customer, Set_Billing_Customer] = useState(null);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopupForm, setopenPopupForm] = useState(false); // customer form popup state
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [searchCustomerName, setSearchCustomerName] = useState(null);
  const [Receipt_Date, Set_Receipt_Date] = useState(new Date()); // receipt date selection state
  const [Open_Popup_Receipt_Date, Set_Open_Popup_Receipt_Date] =
    useState(false);
  const history = useHistory();
  const [Billing_Info, Set_Billing_Info] = useState(null); // recipts data for table maping
  const [receiptCustomer, setReceiptCustomer] = useState({}); // customer data of which receipt is printing
  const [Duration_Date, Set_Duration_Date] = useState(new Date()); // initial date for further programming in receipt
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    rowsPerPage,
    page,
    setPage,
    handleSortRequest,
    handleScroll,
  } = useTable(
    props.Customers_List,
    headCells,
    filterFn,
    componentRef,
    "medium",
    props.totalPages,
    props.currentPage,
    props.totalCustomers,
    props.getAllCustomers,
    props.EmptyCustomer,
    true,
    setLoading
  );
  const handleSearch = async (e) => {
    //     const searchCustomerNameValue = e.target.value
    //   if (searchCustomerNameValue !== '') {
    //     setSearchCustomerName(searchCustomerNameValue)
    //   } else {
    //       await setSearchCustomerName(null)
    //       await props.EmptyCustomer()
    //       await setPage(0)
    //      await  props.getAllCustomers({page:0,limit:rowsPerPage},setLoading)
    //   }
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter(
            (x) =>
              `${x.Customer_Name}${x.Customer_Mobile}`
                .toUpperCase()
                .includes(target.value) ||
              misc
                .capitalize(`${x.Customer_Name}${x.Customer_Mobile}`)
                .includes(target.value) ||
              `${x.Customer_Name}${x.Customer_Mobile}`
                .toLowerCase()
                .includes(target.value)
          );
      },
    });
  };

  const searchCustomer = () => {
    console.log("search customer");
    const data = { searchCustomerName: searchCustomerName };
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post("/api/searchcustomer", data, { headers })
      .then((res) => {
        props.EmptyCustomer();
        props.SetCustomers(res.data, setLoading);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
      });
  };

  useEffect(() => {
    if (searchCustomerName !== null) {
      searchCustomer();
    }
  }, [searchCustomerName]);

  // Bill Print code start

  // generatering dates from 1 to end of month dinamically function start
  const update_dates = (bills, Balance, Receipts) => {
    let dates = [];
    let s_date = new Date(
      Receipt_Date.getFullYear(),
      Receipt_Date.getMonth(),
      0
    ); // 1 date of month
    const e_date = new Date(
      Receipt_Date.getFullYear(),
      Receipt_Date.getMonth() + 1,
      0
    ); // end date of month

    for (let i = s_date; i < e_date; ) {
      s_date.setDate(s_date.getDate() + 1);
      dates.push(misc.formatDateyearmonthday(s_date));
    }
    console.log("update_dates" + Balance);
    mergeArrays(dates, bills, Balance, Receipts);
  };
  // generatering dates from 1 to end of month dinamically function end

  // merge dates and Billing_Info arrays based on date match function start
  const mergeArrays = async (arr1 = [], arr2 = [], Balance, Receipts) => {
    console.log("mergeArrays" + Balance);
    let res = [];
    res = arr1.map((date) => {
      const index = arr2.findIndex(
        (el) => misc.formatDateyearmonthday(el.Trans_Date) == date
      );
      const item = index !== -1 ? arr2[index] : {};
      return {
        date,
        ...item,
      };
    });
    await Set_Billing_Info([
      res,
      {
        T_Milk: arr2.reduce((a, b) => a + (b.Milk || 0), 0),
        T_Yog: arr2.reduce((a, b) => a + (b.Yog || 0), 0),
        T_Others: arr2.reduce((a, b) => a + (b.Ghee || 0), 0),
        T_Amount: arr2.reduce((a, b) => a + (b.Amount || 0), 0),
        Balance: Balance != null ? Balance : 0,
        Receipts: Receipts != null ? Receipts : 0,
      },
    ]);
    console.log(Billing_Info);
    PrintReceipt();
  };
  // merge dates and Billing_Info arrays based on date match function end

  //  fetch receipt data function start
  const getMonthlyReceipt = (customer) => {
    console.log(Receipt_Date);
    console.log("get receipt data");
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post(
        "/api/getmonthlyreceipt",
        { Customer_ID: customer.Customer_ID, Receipt_Date: Receipt_Date },
        { headers }
      )
      .then(async (res) => {
        if (res.data.records.length !== 0) {
          await setReceiptCustomer(customer);
          update_dates(
            res.data.records,
            res.data.PrevBalance.Balance,
            res.data.Receipts.Receipts
          );
        } else {
          setNotify({
            isOpen: true,
            message: "Empty Receipt Record For this Customer",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
      });
  };

  let arrayItems;
  arrayItems =
    Billing_Info != null
      ? Billing_Info[0].map((receipt) => {
          let html = (
            <tr>
              {/* receipt.Event == "1"?"M":"E" +  */}
              <td style={{ textAlign: "center", fontSize: "10pt" }}>
                {misc.LZero(new Date(receipt.date).getDate(), 2)}
              </td>
              <td
                style={{
                  textAlign: "right",
                  paddingRight: "5px",
                  fontSize: "10pt",
                }}
              >
                {receipt.Milk ? misc.currencyWithOutDecimal(receipt.Milk) : "-"}
              </td>
              <td
                style={{
                  textAlign: "right",
                  paddingRight: "5px",
                  fontSize: "10pt",
                }}
              >
                {receipt.Yog ? misc.currencyWithOutDecimal(receipt.Yog) : "-"}
              </td>
              <td
                style={{
                  textAlign: "right",
                  paddingRight: "5px",
                  fontSize: "10pt",
                }}
              >
                {receipt.Ghee ? misc.currencyWithOutDecimal(receipt.Ghee) : "-"}
              </td>
              <td
                style={{
                  textAlign: "right",
                  paddingRight: "5px",
                  fontSize: "10pt",
                }}
              >
                {receipt.Amount
                  ? misc.currencyWithOutDecimal(receipt.Amount)
                  : "-"}
              </td>
            </tr>
          );
          return html;
        })
      : null;
  // generating receipt table rows dynamically end

  // print receipt function start
  const PrintReceipt = () => {
    var sOption = "width=1000,height=1000,left=100,top=25";
    var sWinHTML = document.getElementById("invoice-POS").innerHTML;
    var winprint = window.open("", "", sOption);
    winprint.document.open();
    winprint.document.write("<html><head><style>");
    winprint.document.write(`
           @media screen{
            @page{width:78mm;}
              
             /* customer_List -table */
             table{
               border-collapse: collapse;
               width: -webkit-fill-available;
             }
             /* customer_List - table rows and column  */
             td, th {
               padding: 2px;
               border: 1px solid black;
               text-align: left;
             
             }

             #pagebreak{ page-break-before: always; }
             .pagebreak{ page-break-before: always; }
             
           }
          
          
          @media print{
        
            
              
             table{
               border-collapse: collapse;
               width: -webkit-fill-available;
             }
             /* customer_List - table rows and column  */
             td, th {
               padding: 2px;
               border: 1px solid black;
               text-align: left;
             
             }
             #pagebreak{ page-break-before: always; }
             .pagebreak{ page-break-before: always; }
             
           }
          `);

    winprint.document.write(
      `</style></head><body style="margin:auto" onload="window.print()">`
    );
    winprint.document.write(sWinHTML);
    winprint.document.write("</body></html>");
    //    winprint.document.close();
    winprint.focus();
    winprint.print();
    //    if( !/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //     winprint.close();
    //    }
    setReceiptCustomer({});
    return true;
  };
  // print receipt function end

  // bill print code end
  const insertCustomer = (customer) => {
    console.log("insert run");
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post("/api/addcustomer", customer, { headers: headers })
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });

        if (res.data.iserror === false) {
          props.EmptyCustomer();
          setPage(0);
          props.getAllCustomers({ page: 0, limit: rowsPerPage }, setLoading);
        }
      });
  };
  const updateCustomer = (customer) => {
    console.log("update run");
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post("/api/updatecustomer", customer, { headers: headers })
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        if (res.data.iserror === false) {
          props.EmptyCustomer();
          setPage(0);
          props.getAllCustomers({ page: 0, limit: rowsPerPage }, setLoading);
        }
      });
  };
  const addOrEdit = (customer, resetForm) => {
    if (!customer.Customer_ID) {
      insertCustomer(customer);
    } else if (customer.Customer_ID) {
      updateCustomer(customer);
    }
    resetForm();
    setRecordForEdit(null);
    setopenPopupForm(false);
  };

  const openInPopupForm = (item) => {
    setRecordForEdit(item);
    setopenPopupForm(true);
  };

  const openInPopupReceipt = (Customer) => {
    Set_Billing_Customer(Customer);
    Set_Open_Popup_Receipt_Date(true);
  };
  const deleteCustomer = (CuntomerName, Customer_ID) => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post(
        "/api/deletecustomer",
        { Customer_ID: Customer_ID },
        { headers: headers }
      )
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        if (res.data.iserror === false) {
          props.EmptyCustomer();
          setPage(0);
          props.getAllCustomers({ page: 0, limit: rowsPerPage }, setLoading);
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.message,
          type: err.response.data.iserror ? "error" : "success",
        });
      });
  };
  const onDeleteCustomer = (CuntomerName, Customer_ID) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteCustomer(null, Customer_ID);
  };
  return (
    <div className={classes.root}>
      <PageHeader
        title={props.customerLanguage.Customer.Customer_Header.Title}
        icon={<AccountBox color="primary" fontSize="large" />}
      >
        <Grid
          container
          spacing={1}
          className={classes.ToolbarItems}
          lg={6}
          md={7}
          sm={12}
          xs={12}
        >
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Controls.Input
              label={
                props.customerLanguage.Customer.Customer_Header.SearchInputTitle
              }
              width="100%"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid spacing={1} container lg={6} md={6} sm={6} xs={6}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <IconButton
                variant="outlined"
                className={classes.newButtonMobile}
                onClick={() => {
                  setopenPopupForm(true);
                  setRecordForEdit(null);
                }}
              >
                <Box borderRadius="50%" {...defaultProps}>
                  <AddIcon />
                </Box>
              </IconButton>
              <Controls.Button
                text={
                  props.customerLanguage.Customer.Customer_Header.Add_Button
                }
                width="100%"
                height="4rem"
                startIcon={<AddIcon />}
                className={classes.newButton}
                onClick={() => {
                  setopenPopupForm(true);
                  setRecordForEdit(null);
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <IconButton
                className={classes.newButtonMobile}
                onClick={() => history.push("/customersprint")}
              >
                <Box borderRadius="50%" {...defaultProps}>
                  <Print />
                </Box>
              </IconButton>
              <Controls.Button
                text={
                  props.customerLanguage.Customer.Customer_Header.Print_Button
                }
                width="100%"
                height="4rem"
                startIcon={<Print />}
                className={classes.newButton}
                onClick={() => history.push("/customersprint")}
              />
            </Grid>
            {/* <IconButton
                        
                        className={classes.newButtonMobile}
                        onClick={history.push("/customersprint")}
                    ><Box borderRadius="50%" {...defaultProps}><FileCopy /></Box></IconButton>
                    <Controls.Button
                        text="Excel"
                        width="100%"
                        height="4rem"
                        startIcon={<FileCopy/>}
                        className={classes.newButton}
                        onClick={()=>misc.exportTableToExcel('table')}
                    /> */}
          </Grid>
        </Grid>
      </PageHeader>
      <Paper elevation={7} className={classes.pageContent}>
        <div
          className={`table-container ${
            localStorage.getItem("dodaily_urdu") == "true"
              ? "UrduFontTableHead"
              : ""
          }`}
          onScroll={handleScroll}
        >
          <TblContainer>
            <TblHead />
            <TableBody>
              {props.Customers_List !== null ? (
                recordsAfterPagingAndSorting().map((Customers_List_Customer) =>
                  localStorage.getItem("dodaily_urdu") == "false" ? (
                    <TableRow key={Customers_List_Customer.Customer_ID}>
                      <TableCell name="name" data-heading="Customer Name">
                        <p>{Customers_List_Customer.Customer_Name}-</p>
                        <p>{Customers_List_Customer.Customer_Address}</p>
                      </TableCell>
                      <TableCell name="mobile" data-heading="Mobile">
                        {Customers_List_Customer.Customer_Mobile}
                      </TableCell>
                      <TableCell name="qty" data-heading="Morning Milk QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Milk_QTY
                        )}
                      </TableCell>
                      <TableCell name="qty" data-heading="Morning Yog QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Yog_QTY
                        )}
                      </TableCell>
                      <TableCell name="qty" data-heading="Morning Others QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Ghee_QTY
                        )}
                      </TableCell>
                      <TableCell name="qty" data-heading="Evening Milk QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Milk_QTY
                        )}
                      </TableCell>
                      <TableCell name="qty" data-heading="Evening Yog QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Yog_QTY
                        )}
                      </TableCell>
                      <TableCell name="qty" data-heading="Evening Others QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Ghee_QTY
                        )}
                      </TableCell>
                      <TableCell name="rate" data-heading="Milk Rate">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Milk_Rate
                        )}
                      </TableCell>
                      <TableCell name="rate" data-heading="Yog Rate">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Yog_Rate
                        )}
                      </TableCell>
                      <TableCell name="rate" data-heading="Others Rate">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Ghee_Rate
                        )}
                      </TableCell>
                      <TableCell name="active" data-heading="Active">
                        {Customers_List_Customer.Customer_Active === 0
                          ? "No"
                          : "Yes"}
                      </TableCell>
                      <TableCell
                        name="actions"
                        className={classes.ActionBtnDiv}
                        data-heading="Action"
                      >
                        <>
                          <Controls.ActionButton
                            colorPrimary="colorPrimary"
                            onClick={() => {
                              openInPopupForm(Customers_List_Customer);
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </Controls.ActionButton>
                          <Controls.ActionButton
                            colorPrimary="colorSecondary"
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: "Are you sure to delete ?",
                                subTitle: "You can't undo this operation",
                                onConfirm: () => {
                                  onDeleteCustomer(
                                    null,
                                    Customers_List_Customer.Customer_ID
                                  );
                                },
                              });
                            }}
                          >
                            <Delete fontSize="small" />
                          </Controls.ActionButton>
                          <Controls.ActionButton
                            colorPrimary="colorSecondary"
                            onClick={() =>
                              openInPopupReceipt(Customers_List_Customer)
                            }
                          >
                            <Print fontSize="small" />
                          </Controls.ActionButton>
                        </>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={Customers_List_Customer.Customer_ID}>
                      <TableCell
                        name="actions"
                        className={classes.ActionBtnDiv}
                        data-heading="اعمال"
                      >
                        <>
                          <Controls.ActionButton
                            colorPrimary="colorPrimary"
                            onClick={() => {
                              openInPopupForm(Customers_List_Customer);
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </Controls.ActionButton>
                          <Controls.ActionButton
                            colorPrimary="colorSecondary"
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: "Are you sure to delete ?",
                                subTitle: "You can't undo this operation",
                                onConfirm: () => {
                                  onDeleteCustomer(
                                    null,
                                    Customers_List_Customer.Customer_ID
                                  );
                                },
                              });
                            }}
                          >
                            <Delete fontSize="small" />
                          </Controls.ActionButton>
                          <Controls.ActionButton
                            colorPrimary="colorSecondary"
                            onClick={() =>
                              openInPopupReceipt(Customers_List_Customer)
                            }
                          >
                            <Print fontSize="small" />
                          </Controls.ActionButton>
                        </>
                      </TableCell>
                      <TableCell name="active" data-heading="فعال">
                        {Customers_List_Customer.Customer_Active === 0
                          ? "No"
                          : "Yes"}
                      </TableCell>
                      <TableCell name="rate" data-heading="دوسروں کی قیمت">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Ghee_Rate
                        )}
                      </TableCell>
                      <TableCell name="rate" data-heading="Yog Rate">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Yog_Rate
                        )}
                      </TableCell>
                      <TableCell name="rate" data-heading="Milk Rate">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Milk_Rate
                        )}
                      </TableCell>
                      <TableCell name="qty" data-heading="Evening Others QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Ghee_QTY
                        )}
                      </TableCell>
                      <TableCell name="qty" data-heading="Evening Yog QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Yog_QTY
                        )}
                      </TableCell>
                      <TableCell name="qty" data-heading="Evening Milk QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Milk_QTY
                        )}
                      </TableCell>
                      <TableCell name="qty" data-heading="Morning Others QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Ghee_QTY
                        )}
                      </TableCell>
                      <TableCell name="qty" data-heading="Morning Yog QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Yog_QTY
                        )}
                      </TableCell>
                      <TableCell name="qty" data-heading="Morning Milk QTY">
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Milk_QTY
                        )}
                      </TableCell>
                      <TableCell name="mobile" data-heading="Mobile">
                        {Customers_List_Customer.Customer_Mobile}
                      </TableCell>
                      <TableCell name="name" data-heading="Customer Name">
                        <p>{Customers_List_Customer.Customer_Urdu_Name}-</p>
                        <p>{Customers_List_Customer.Customer_Address}</p>
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <Controls.Loader />
              )}
              {page < props.totalPages ? (
                <Controls.Button
                  id="scroll_Btn"
                  width="100%"
                  height="4rem"
                  text="Load"
                  onClick={() =>
                    page < props.totalPages ? setPage(page + 1) : null
                  }
                ></Controls.Button>
              ) : null}
            </TableBody>
          </TblContainer>
          {loading ? <Controls.Loader /> : null}
          {!loading && props.Customers_List.length === 0 ? (
            <Typography
              variant="h4"
              style={{ margin: "auto", display: "block", textAlign: "center" }}
            >
              No Record
            </Typography>
          ) : null}
        </div>
        <table
          id="table"
          style={{
            width: " 100%",
            borderCollapse: "collapse",
            border: "1px solid black",
            display: "none",
          }}
        >
          <tr>
            {headCells.map((Customers_List_Customer, index) =>
              Customers_List_Customer.label !== "Actions" ? (
                <th key={index} style={{ border: "1px solid black" }}>
                  {Customers_List_Customer.label}
                </th>
              ) : null
            )}
          </tr>
          {props.Customers_List
            ? recordsAfterPagingAndSorting().map(
                (Customers_List_Customer, index) =>
                  localStorage.getItem("dodaily_urdu") === false ? (
                    <tr key={index}>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        data-heading="Customer Name"
                      >
                        {Customers_List_Customer.Customer_Name}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        data-heading="Mobile"
                      >
                        {Customers_List_Customer.Customer_Mobile}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="amount"
                        data-heading="Morning Milk QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Milk_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="amount"
                        data-heading="Morning Yog QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Yog_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="amount"
                        data-heading="Morning Others QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Ghee_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="amount"
                        data-heading="Evening Milk QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Milk_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="amount"
                        data-heading="Evening Yog QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Yog_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="amount"
                        data-heading="Evening Others QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Ghee_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="amount"
                        data-heading="Milk Rate"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Milk_Rate
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="amount"
                        data-heading="Yog Rate"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Yog_Rate
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="amount"
                        data-heading="Others Rate"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Ghee_Rate
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        data-heading="Active"
                      >
                        {Customers_List_Customer.Customer_Active === 0
                          ? "No"
                          : "Yes"}
                      </td>
                    </tr>
                  ) : (
                    <tr key={index}>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="rate"
                        data-heading="دوسروں کی قیمت"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Ghee_Rate
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="rate"
                        data-heading="Yog Rate"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Yog_Rate
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="rate"
                        data-heading="Milk Rate"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Milk_Rate
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="qty"
                        data-heading="Evening Others QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Ghee_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="qty"
                        data-heading="Evening Yog QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Yog_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="qty"
                        data-heading="Evening Milk QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Evening_Milk_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="qty"
                        data-heading="Morning Others QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Ghee_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="qty"
                        data-heading="Morning Yog QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Yog_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="qty"
                        data-heading="Morning Milk QTY"
                      >
                        {misc.currencyOnlyDecimal(
                          Customers_List_Customer.Customer_Morning_Milk_QTY
                        )}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="mobile"
                        data-heading="Mobile"
                      >
                        {Customers_List_Customer.Customer_Mobile}
                      </td>
                      <td
                        style={{ border: "1px solid black", color: "black" }}
                        name="name"
                        data-heading="Customer Name"
                      >
                        <p>{Customers_List_Customer.Customer_Urdu_Name}-</p>
                        <p>{Customers_List_Customer.Customer_Address}</p>
                      </td>
                    </tr>
                  )
              )
            : null}
        </table>
      </Paper>
      <Popup
        title={props.customerLanguage.Customer.Customer_Header.Title}
        openPopup={openPopupForm}
        setOpenPopup={setopenPopupForm}
      >
        <CustomerForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          formLanguage={props.customerLanguage.Customer.Customer_Form}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        title={props.customerLanguage.Customer.Customer_Bill_Form.title}
        openPopup={Open_Popup_Receipt_Date}
        setOpenPopup={Set_Open_Popup_Receipt_Date}
      >
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Controls.DateImputMaterialUi
              views={["month", "year"]}
              format="MM/yyyy"
              value={Receipt_Date}
              onChange={(Receipt_Date) => {
                Set_Receipt_Date(Receipt_Date);
              }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Controls.Button
              onClick={() => getMonthlyReceipt(Billing_Customer)}
              text={
                props.customerLanguage.Customer.Customer_Bill_Form.Print_Button
              }
              width="100%"
              height="4rem"
            />
          </Grid>
        </Grid>
      </Popup>
      {/* Print html Start */}
      <div
        id="invoice-POS"
        style={{
          width: " 100%",
          borderCollapse: "collapse",
          border: "1px solid black",
          display: "none",
        }}
      >
        <h2 style={{ textAlign: "center" }}>
          {sessionStorage.getItem("usershop")}
        </h2>
        <div
          style={{
            marginBottom: "4px",
            height: "0px",
            backgroundColor: "black",
            color: "black",
            width: "100%",
            outline: "none",
            border: "1px solid black",
            display: "block",
          }}
        ></div>
        <div
          style={{ width: "50%", display: "inline-block", float: "left" }}
          id="info"
        >
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "left",
            }}
          >
            {receiptCustomer.Customer_Name}
          </p>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "left",
            }}
          >
            {receiptCustomer.Customer_Mobile}
          </p>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "left",
            }}
          >
            Milk Rate: {receiptCustomer.Customer_Milk_Rate}
          </p>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "left",
            }}
          >
            Yog Rate: {receiptCustomer.Customer_Yog_Rate}
          </p>
        </div>
        <div
          style={{ width: "50%", display: "inline-block", float: "right" }}
          id="info"
        >
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "right",
            }}
          >
            {`${
              monthNames[Receipt_Date.getMonth()]
            }-${Receipt_Date.getFullYear()}`}
          </p>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left", width: "50%" }}>Bal :</span>{" "}
            <span style={{ float: "left", width: "50%" }}>
              {Billing_Info != null
                ? misc.currencyWithOutDecimal(Billing_Info[1].Balance)
                : 0}
            </span>
          </p>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left", width: "50%" }}>Rcv :</span>{" "}
            <span style={{ float: "left", width: "50%" }}>
              {Billing_Info != null
                ? misc.currencyWithOutDecimal(Billing_Info[1].Receipts)
                : 0}
            </span>
          </p>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left", width: "50%" }}> Opn :</span>{" "}
            <span style={{ float: "left", width: "50%" }}>
              {Billing_Info != null
                ? misc.currencyWithOutDecimal(
                    Billing_Info[1].Balance - Billing_Info[1].Receipts
                  )
                : 0}
            </span>
          </p>
        </div>

        <div id="table">
          <table className="table-container__print_table">
            <tr>
              <th style={{ fontSize: "10pt" }}>Date</th>
              <th style={{ fontSize: "10pt" }}>Milk </th>
              <th style={{ fontSize: "10pt" }}>Yog </th>
              <th style={{ fontSize: "10pt" }}>Others </th>
              <th style={{ fontSize: "10pt" }}>Amount</th>
            </tr>
            {arrayItems}
            <td style={{ fontWeight: "bold", fontSize: "10pt" }}>Total</td>
            <td style={{ textAlign: "right", fontSize: "10pt" }}>
              {Billing_Info != null
                ? misc.currencyWithOutDecimal(Billing_Info[1].T_Milk)
                : null}
            </td>
            <td style={{ textAlign: "right", fontSize: "10pt" }}>
              {Billing_Info != null
                ? misc.currencyWithOutDecimal(Billing_Info[1].T_Yog)
                : null}
            </td>
            <td style={{ textAlign: "right", fontSize: "10pt" }}>
              {Billing_Info != null
                ? misc.currencyWithOutDecimal(Billing_Info[1].T_Others)
                : null}
            </td>
            <td style={{ textAlign: "right", fontSize: "10pt" }}>
              {Billing_Info != null
                ? misc.currencyWithOutDecimal(Billing_Info[1].T_Amount)
                : null}
            </td>
          </table>
        </div>
        <div id="info">
          <p style={{ textAlign: "right", fontSize: "10pt" }}>
            Bill's Total :
            {Billing_Info != null
              ? misc.currencyWithOutDecimal(Billing_Info[1].T_Amount)
              : 0}
            <br></br>
            {/* Receipts : {Billing_Info !=null?Billing_Info[1].Receipts:0}<br></br> */}
            Current Balance: :{" "}
            {Billing_Info != null
              ? misc.currencyWithOutDecimal(
                  Billing_Info[1].T_Amount +
                    (Billing_Info[1].Balance - Billing_Info[1].Receipts)
                )
              : 0}
            <br></br>
          </p>
        </div>
        <div
          style={{
            height: "0px",
            backgroundColor: "black",
            color: "black",
            width: "100%",
            outline: "none",
            border: "1px solid black",
            display: "block",
          }}
        ></div>
        <div>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "center",
            }}
          >
            {sessionStorage.getItem("usercontactperson")}-
            {sessionStorage.getItem("usermobile")}
            <br></br>
            {sessionStorage.getItem("usershopaddress")}
          </p>
        </div>

        <div
          style={{
            height: "0px",
            backgroundColor: "black",
            color: "black",
            width: "100%",
            outline: "none",
            border: "1px solid black",
            display: "block",
          }}
        ></div>
        <div>
          <p
            style={{
              fontSize: "10pt",
              margin: "0",
              marginBottom: "4pt",
              textAlign: "center",
            }}
          >
            Powered By : DoDaily.pk
          </p>
          <div id="pagebreak" className="pagebreak"></div>
        </div>
      </div>
      {/* Print html end */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  customerLanguage: state.language.language,
  Customers_List: state.CustomerList.Customers_List,
  totalPages: state.CustomerList.totalPages,
  currentPage: state.CustomerList.currentPage,
  totalCustomers: state.CustomerList.totalCustomers,
});
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => {
    dispatch(changeLanguage(language));
  },
  SetCustomers: (data, setLoading) => {
    dispatch(SetCustomers(data, setLoading));
  },
  getAllCustomers: (data, setLoading) => {
    dispatch(GetCustomers(data, setLoading));
  },
  EmptyCustomer: () => {
    dispatch(EmptyCustomer());
  },
  DeleteCustomer: (Customer_ID, totalPages, totalCustomers) => {
    dispatch(DeleteCustomer(Customer_ID, totalPages, totalCustomers));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
