import React, { useState, memo, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import OrderForm from "./OrderForm";
import Popup from "../../components/Popup";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Grid,
  IconButton,
  Box,
  Typography,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import { connect } from "react-redux";
import axios from "axios";
import {
  Print,
  Search,
  HighlightOff,
  DeleteForever,
  Edit,
  ViewList,
  FilterList,
} from "@material-ui/icons";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import {
  setDailyOrders,
  emptyDailyOrderList,
  DeleteDailyOrderFromReduxState,
} from "../../redux/actions/DailyOrdersAction";
import misc from "../../fnc/misc";
import $ from "jquery";
import jsPDF from "jspdf";

const innerHeight = window.innerHeight;
const innerWidth = window.innerWidth;
const useStyles = makeStyles((theme) => ({
  root: {
    "& .pageHeaderPageInfo": {
      display: "none !important",
    },
    "& .MuiGrid-spacing-xs-2 ": {
      margin: "0rem",
    },
  },
  pageContent: {
    padding: "1rem 1rem 1rem 1rem",
    height: `calc(100vh - 18rem)`,
    [theme.breakpoints.down("md")]: {
      height: `calc(100vh - 18rem)`,
    },
    [theme.breakpoints.down("sm")]: {
      height: `calc(100vh - 22rem)`,
    },
    [theme.breakpoints.down("xs")]: {
      height: `calc(100vh - 26rem)`,
    },
    overflow: "hiddin",
    "& .MuiGrid-item > *": {
      maxWidth: `calc(100% - 1rem) !important`,
      width: `calc(100% - 1rem) !important`,
      marginRight: `1rem !important`,
    },
    "& .table-container": {
      height: `100%`,
      overflow: "auto",
      "& thead": {
        "& [name=actions]": {
          minWidth: "5rem !important",
        },
        "& [name=event]": {
          minWidth: "5rem !important",
        },
        "&  tr": {
          "& [name=date]": {
            minWidth: "10rem !important",
          },
          "& [name=name]": {
            minWidth: "10rem !important",
          },

          "& [name=amount]": {
            minWidth: "6rem !important",
          },
          "& [name=rate]": {
            minWidth: "5rem !important",
          },
          "& [name=qty]": {
            minWidth: "5rem !important",
          },
          "& [name=detail]": {
            minWidth: "7rem !important",
          },
          "& [name=totalamount]": {
            minWidth: "9rem !important",
          },

          [theme.breakpoints.down(900)]: {
            "& [name=actions]": {
              minWidth: "auto !important",
            },
            "& [name=event]": {
              minWidth: "auto !important",
            },
            "& [name=date]": {
              minWidth: "auto !important",
            },
            "& [name=name]": {
              width: "auto !important",
            },
            "& [name=qty]": {
              width: "auto !important",
            },
            "& [name=amount]": {
              width: "auto !important",
            },
            "& [name=rate]": {
              width: "auto !important",
            },
            "& [name=detail]": {
              width: "auto !important",
            },
            "& [name=totalamount]": {
              minWidth: "auto !important",
            },
          },
        },
      },
      "& tbody": {
        "&  tr": {
          "& td": {
            [theme.breakpoints.down(900)]: {
              paddingLeft: "20rem",
            },
            [theme.breakpoints.down(539)]: {
              paddingLeft: "15rem",
            },
          },
          "& [name=actions]": {
            minWidth: "5rem !important",
          },
          "& [name=event]": {
            minWidth: "5rem !important",
            textAlign: "center",
          },
          "& [name=date]": {
            minWidth: "10rem !important",
          },
          "& [name=name]": {
            minWidth: "10rem !important",
          },

          "& [name=amount]": {
            minWidth: "6rem !important",
          },
          "& [name=rate]": {
            minWidth: "5rem !important",
          },
          "& [name=qty]": {
            minWidth: "5rem !important",
          },
          "& [name=detail]": {
            minWidth: "9rem !important",
          },
          "& [name=totalamount]": {
            minWidth: "9rem !important",
          },
          [theme.breakpoints.down(900)]: {
            "& [name=actions]": {
              minWidth: "auto !important",
            },
            "& [name=event]": {
              minWidth: "auto !important",
            },
            "& [name=date]": {
              minWidth: "auto !important",
            },
            "& [name=name]": {
              width: "auto !important",
            },
            "& [name=rate]": {
              width: "auto !important",
            },
            "& [name=qty]": {
              width: " auto !important",
            },
            "& [name=amount]": {
              width: "auto !important",
            },
            "& [name=detail]": {
              width: "auto !important",
              height: "2rem",
            },
            "& [name=totalamount]": {
              minWidth: "auto !important",
            },
          },
        },
      },
      "& #scroll_Btn": {
        margin: "auto",
        display: "none",
        [theme.breakpoints.between("xs", "sm")]: {
          display: "block",
        },
      },
    },
  },
  ToolbarItems: {
    display: "flex",
    alignItems: "center",
  },
  buttonContainerGrid: {
    display: "flex",
    alignItems: "center",
    marginBottom: `1rem !important`,
    justifyContent: "flex-end",
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "2rem",
    },
    "& button": {
      width: "32.333%",
      [theme.breakpoints.between("md", "xl")]: {
        marginRight: ".3rem",
      },
    },
  },
  newButtonMobile: {
    padding: 0,
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  newButton: {
    height: "4rem",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  ActionBtnDiv: {
    [theme.breakpoints.down(900)]: {
      // display:"flex !important",
      // flexDirection:"column"
      "& > *": {
        width: "4rem",
        height: "3rem",
        padding: ".5rem",
      },
      "& svg": {
        fontSize: "1.6rem",
      },
    },
  },
}));

const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "inherit",
  m: 1,
  padding: 0,
  border: 1,
  style: { width: "4rem", height: "4rem" },
  display: "flex",
  margin: "0rem",
  alignItems: "center",
  justifyContent: "center",
};

const DailyOrders = (props) => {
  let headCells =
    props.dailyOrdersLanguage.Daily_Orders.Daily_Orders_Table
      .Daily_Orders_Table_Head;

  const classes = useStyles();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [monthDuration, setMonthDuration] = useState(0);
  const [customer, setCustomer] = useState({
    customerId: null,
    customerName: "",
  });
  const [searchCustomerName, setSearchCustomerName] = useState(null);
  const [customer_List, set_Customer_List] = useState([]);
  const [filter, setFilter] = useState({
    startDateFilter: null,
    endDateFilter: null,
    deliveryTime: null,
  });
  const history = useHistory();
  const [Delivery_Time, set_Delivery_Time] = useState(0);
  const [loading, setLoading] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const handleDeliveryTimeChange = async (e) => {
    set_Delivery_Time(e.target.value);
  };

  $(document).ready(function () {
    $("input").attr("autocomplete", "off");
  });

  //get all daily orders start
  // const getDailyOrders =(data)=>{
  //     console.log("getDailyOrders run")
  //     const headers={
  //       "x-access-token": sessionStorage.getItem("accountingauthkey")
  //   }
  //   axios.get('/api/getdailyorders',{headers:headers}).then(res=>{
  //   props.setDailyOrders(res.data)
  //   setLoading(false)
  //   if(res.data.iserror){
  //       setNotify({
  //     isOpen: true,
  //     message: res.data.message,
  //     type: res.data.iserror ? 'error':'success'
  // })
  // }

  //    }).catch(err=>{
  //     setNotify({
  //         isOpen: true,
  //         message: err.response.data.message,
  //         type: err.response.data.iserror ? 'error':'success'
  //     })
  //    })
  // }
  //get all daily orders end

  //get filtered daily orders start
  const getFilteredDailyOrders = (page, limit) => {
    console.log("getFilteredDailyOrders run");
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post(
        "/api/getfiltereddailyorders",
        {
          startDate: misc.formatDateyearmonthday(startDate),
          endDate: misc.formatDateyearmonthday(endDate),
          monthDuration,
          customerId: customer.customerId,
          searchCustomerName,
          Delivery_Time,
        },
        { headers: headers }
      )
      .then((res) => {
        if (res.data.iserror) {
          setNotify({
            isOpen: true,
            message: res.data.message,
            type: res.data.iserror ? "error" : "success",
          });
        } else {
          props.setDailyOrders(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.message,
          type: err.response.data.iserror ? "error" : "success",
        });
      });
  };

  const ApplyFilter = () => {
    if (customer.customerId != null) {
      props.emptyDailyOrderList();
      getFilteredDailyOrders();
    } else {
      setNotify({
        isOpen: true,
        message: "Please Select Customer First",
        type: "error",
      });
    }
  };

  //get filtered daily orders end
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    rowsPerPage,
    page,
    setPage,
    handleScroll,
    handleSortRequest,
    setOrder,
  } = useTable(
    props.Daliy_Order_List,
    headCells,
    filterFn,
    null,
    "medium",
    props.totalPages,
    props.currentPage,
    props.totalOrders,
    () => {},
    props.emptyDailyOrderList,
    true,
    () => {}
  );

  // const handleSearch = async(e) => {
  // const searchCustomerNameValue = e.target.value
  //   if (searchCustomerNameValue !== '') {
  // setSearchCustomerName(searchCustomerNameValue)
  //   } else {
  //       await setSearchCustomerName(null)
  //   }
  // let target = e.target;
  // setFilterFn({
  //     fn: orders => {
  //         if (target.value == "")
  //             return orders;

  //             return orders.filter(x => `${x.Customer_Name}${misc.formatDate(x.Trans_Date)}`.toString().toLowerCase().includes(target.value) || `${x.Customer_Name}${misc.formatDate(x.Trans_Date)}`.toString().toUpperCase().includes(target.value)|| misc.capitalize(`${x.Customer_Name}${misc.formatDate(x.Trans_Date)}`.toString()).includes(target.value))
  //     }
  // })
  // }
  // const searchDailyOrder = () => {
  //     console.log("search dailyOrder")
  //     const data = {searchCustomerName:searchCustomerName}
  //     const headers={
  //         "x-access-token": sessionStorage.getItem("accountingauthkey")
  //     }
  //     axios.post("/api/searchdailyorder",data,{headers})
  // 		.then((res) =>{
  //             props.emptyDailyOrderList()
  //             props.setDailyOrders(res.data)
  //          })
  //     .catch((err) => {
  //         console.log("ERROR: ====", err);
  //       })
  // }

  // useEffect(()=>{
  //     if (searchCustomerName !== null ) {
  //         searchDailyOrder()
  //     }
  // },[searchCustomerName])

  //clear filter
  const clearFilter = async () => {
    await setStartDate(new Date());
    await setEndDate(new Date());
    // await setSearchCustomerName(null)
    await setCustomer({ customerName: "", customerId: null });
    await set_Delivery_Time(0);
    props.emptyDailyOrderList();
    // getDailyOrders({page:0,limit:rowsPerPage})
  };

  const onClearFilter = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    clearFilter();
  };
  useEffect(() => {
    const duration = misc.monthDiff(startDate, endDate);
    if (duration > 3) {
      setMonthDuration(duration);
      setNotify({
        isOpen: true,
        message: "Month Duration Should Be Less Then Three Months",
        type: "error",
      });
    } else {
      setMonthDuration(duration);
    }
  }, [startDate, endDate]);

  const onDeleteOrder = (Trans_ID) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteOrder(Trans_ID);
  };

  //delete silgle order start
  const deleteOrder = (transOrderId) => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post(
        "/api/deleteDailyOrder",
        { transOrderId: transOrderId },
        { headers: headers }
      )
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        if (res.data.iserror === false) {
          // if(Delivery_Time == 0 && searchCustomerName === "" && startDate == new Date() && endDate== new Date() ){
          //         props.emptyDailyOrderList()
          //         getDailyOrders()
          // }else{
          //     props.emptyDailyOrderList()
          // setPage(0)
          getFilteredDailyOrders();
          // }
        }
      });
    //  .catch(err=>{
    //     setNotify({
    //         isOpen: true,
    //         message: err.response.data.message,
    //         type: err.response.data.iserror ? 'error':'success'
    //     })
    //  })
  };
  // delete DailyOrder End

  //update orders start
  const updateDailyOrder = (transOrder) => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post(
        "/api/updatedailyorder",
        { transOrder: transOrder },
        { headers: headers }
      )
      .then((res) => {
        console.log("update run");
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        // if(res.data.iserror === false){
        //     if(Delivery_Time == 0 && searchCustomerName === "" && startDate == new Date() && endDate== new Date() ){
        //         props.emptyDailyOrderList()
        //         getDailyOrders()
        // }else{
        //     props.emptyDailyOrderList()
        //     setPage(0)
        getFilteredDailyOrders();
        // }
        // }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.message,
          type: err.response.data.iserror ? "error" : "success",
        });
      });
  };
  const openInPopup = (transOrder) => {
    setRecordForEdit(transOrder);
    setOpenPopup(true);
  };
  const addOrEdit = (transOrder, resetForm) => {
    if (transOrder.Trans_ID) {
      updateDailyOrder(transOrder);
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
  };
  //update orders end

  // customer fucctionality start //

  const getCustomers = () => {
    const data = {
      searchCustomerName: searchCustomerName,
    };
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post("/api/getsearchcustomers", data, { headers })
      .then((res) => {
        set_Customer_List(res.data.Customers);
        console.log(res.data.Customers);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
      });
  };

  let setCustomerList = (customer) => {
    setCustomer({
      customerId: customer.Customer_ID,
      customerName: customer.Customer_Name,
    });
    setSearchCustomerName(null);
  };

  const changeSearchCustomerState = (e) => {
    const searchCustomerNameValue = e.target.value;
    if (searchCustomerNameValue !== "") {
      setSearchCustomerName(searchCustomerNameValue);
      setCustomer({ ...customer, customerName: searchCustomerNameValue });
    } else {
      setSearchCustomerName(null);
      setCustomer({ customerId: null, customerName: "" });
    }
  };

  setCustomerList = setCustomerList.bind(this);
  useEffect(() => {
    getCustomers();
  }, [searchCustomerName]);
  // customer fucctionality end //
  return (
    <div className={classes.root}>
      <PageHeader
        title="Daily Orders"
        icon={<ViewList color="primary" fontSize="large" />}
        height="maxHeight"
      >
        <Grid
          container
          className={classes.ToolbarItems}
          spacing={1}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item lg={1} md={1} sm={2} xs={3}>
            <Controls.DateImputMaterialUi
              label={
                props.dailyOrdersLanguage.Daily_Orders.Daily_Orders_Header
                  .Input_From
              }
              name="startDate"
              id="input_start_Date"
              value={startDate}
              onChange={(startDate) => setStartDate(startDate)}
            ></Controls.DateImputMaterialUi>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={3}>
            <Controls.DateImputMaterialUi
              label={
                props.dailyOrdersLanguage.Daily_Orders.Daily_Orders_Header
                  .Input_To
              }
              name="endDate"
              id="input_end_Date"
              nextid="input_dailyOrder_search"
              value={endDate}
              onChange={(endDate) => setEndDate(endDate)}
            ></Controls.DateImputMaterialUi>
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={6}>
            <div className="form_search_customer">
              <Controls.SearchList
                Input_ID="input_customer_name"
                ItemName="Customer_Name"
                ItemAddress="Customer_Address"
                form_Name="customer"
                items={customer_List}
                setSelected={setCustomerList}
              >
                <Controls.Input
                  label={
                    props.dailyOrdersLanguage.Daily_Orders.Daily_Orders_Header
                      .Input_Customer_Name
                  }
                  // width="100%"
                  // marginLeft="2rem"
                  name="Customer_Name"
                  id="input_customer_name"
                  nextid="input_MMQ"
                  value={customer.customerName}
                  onChange={changeSearchCustomerState}
                  placeholder="Select Customer"
                  aria-activedescenda
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                ></Controls.Input>
              </Controls.SearchList>
            </div>
          </Grid>{" "}
          <Grid item lg={2} md={2} sm={2} xs={4}>
            <Controls.Select
              name="Delivery_Time"
              label={
                props.dailyOrdersLanguage.Daily_Orders.Daily_Orders_Header
                  .Input_Delivery_Time
              }
              id="select_Delivery_Time"
              value={Delivery_Time}
              onChange={handleDeliveryTimeChange}
              options={[
                { id: 0, title: "All" },
                { id: 1, title: "Morning" },
                { id: 2, title: "Evening" },
              ]}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={1} xs={2}>
            <IconButton
              className={classes.newButtonMobile}
              onClick={ApplyFilter}
            >
              <Box borderRadius="50%" {...defaultProps}>
                <FilterList />
              </Box>
            </IconButton>
            <Controls.Button
              text={
                props.dailyOrdersLanguage.Daily_Orders.Daily_Orders_Header
                  .Button_Filter
              }
              width="100%"
              height="4rem"
              startIcon={<FilterList />}
              className={classes.newButton}
              onClick={ApplyFilter}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={1} xs={2}>
            <IconButton
              className={classes.newButtonMobile}
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: `Are you sure you want to Clear Filter this ?`,
                  onConfirm: onClearFilter,
                });
              }}
            >
              <Box borderRadius="50%" {...defaultProps}>
                <HighlightOff />
              </Box>
            </IconButton>
            <Controls.Button
              text={
                props.dailyOrdersLanguage.Daily_Orders.Daily_Orders_Header
                  .Button_Clear_Filter
              }
              width="100%"
              height="4rem"
              startIcon={<HighlightOff />}
              className={classes.newButton}
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: `Are you sure you want to Clear Filter this ?`,
                  onConfirm: onClearFilter,
                });
              }}
            />
          </Grid>
          {/* <Grid item lg={2} md={4} sm={3} xs={5} >
                    <IconButton
                        
                        className={classes.newButtonMobile}
                        onClick={()=>misc.exportTableToExcel('table')}
                    ><Box borderRadius="50%" {...defaultProps}><FileCopy /></Box></IconButton>
                    <Controls.Button
                        text="Excel"
                        width="100%"
                        height="4rem"
                        startIcon={<FileCopy/>}
                        className={classes.newButton}
                        onClick={()=>misc.exportTableToExcel('table')}
                    />
                    </Grid> */}
        </Grid>
      </PageHeader>
      <Paper className={classes.pageContent} elevation={7}>
        <div
          className={`table-container ${
            localStorage.getItem("dodaily_urdu") == "true"
              ? "UrduFontTableHead"
              : ""
          }`}
          onScroll={handleScroll}
        >
          <TblContainer>
            <TblHead cellId={"OrderDate"} />
            <TableBody>
              {props.Daliy_Order_List !== null ? (
                recordsAfterPagingAndSorting().map((DailyOrderList_Order) => (
                  <TableRow key={DailyOrderList_Order.Order_ID}>
                    <TableCell name="actions" data-heading="Action">
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure you want to Delete this ?",
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDeleteOrder(DailyOrderList_Order.Trans_ID);
                            },
                          });
                        }}
                        colorPrimary="colorSecondary"
                      >
                        <DeleteForever fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          openInPopup(DailyOrderList_Order);
                        }}
                        colorPrimary="colorPrimary"
                      >
                        <Edit fontSize="small" />
                      </Controls.ActionButton>
                    </TableCell>
                    <TableCell name="event" data-heading="Event">
                      {DailyOrderList_Order.Trans_Event_ID == 1 ? "M" : "E"}
                    </TableCell>
                    <TableCell name="date" data-heading="Date">
                      {misc
                        .formatDate(DailyOrderList_Order.Trans_Date)
                        .toString()}
                    </TableCell>
                    <TableCell name="name" data-heading="Customer Name">
                      {DailyOrderList_Order.Customer_Name}
                    </TableCell>
                    <TableCell name="qty" data-heading="Milk Qty ">
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Milk_Qty
                      )}
                    </TableCell>
                    <TableCell name="rate" data-heading="Milk Rate ">
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Milk_Rate
                      )}
                    </TableCell>
                    <TableCell name="amount" data-heading="Milk Amount">
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Milk_Amount
                      )}
                    </TableCell>
                    <TableCell name="qty" data-heading="Yog Qty ">
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Yog_Qty
                      )}
                    </TableCell>
                    <TableCell name="rate" data-heading="Yog Rate ">
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Yog_Rate
                      )}
                    </TableCell>
                    <TableCell name="amount" data-heading="Yog Amount">
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Yog_Amount
                      )}
                    </TableCell>
                    <TableCell name="qty" data-heading="Other Qty ">
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Ghee_Qty
                      )}
                    </TableCell>
                    <TableCell name="rate" data-heading="Other Rate ">
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Ghee_Rate
                      )}
                    </TableCell>
                    <TableCell name="amount" data-heading="Other Amount">
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Ghee_Amount
                      )}
                    </TableCell>
                    <TableCell name="detail" data-heading="Detail ">
                      {DailyOrderList_Order.Trans_Detail}
                    </TableCell>
                    <TableCell name="totalamount" data-heading="Payable">
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Ghee_Amount +
                          DailyOrderList_Order.Trans_Yog_Amount +
                          DailyOrderList_Order.Trans_Milk_Amount
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Controls.Loader />
              )}
              {/* {page < props.totalPages?<Controls.Button id="scroll_Btn"  width="100%" height="4rem" text="Load" onClick={()=>page < props.totalPages ? setPage(page+1):null}></Controls.Button>:null} */}
            </TableBody>
          </TblContainer>
          {loading ? <Controls.Loader /> : null}
          {!loading && props.Daliy_Order_List.length === 0 ? (
            <Typography
              variant="h4"
              style={{ margin: "auto", display: "block", textAlign: "center" }}
            >
              No Record
            </Typography>
          ) : null}
        </div>

        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Popup
          title={props.dailyOrdersLanguage.Daily_Orders.Daily_Orders_Form.Title}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <OrderForm
            recordForEdit={recordForEdit}
            addOrEdit={addOrEdit}
            formLanguage={
              props.dailyOrdersLanguage.Daily_Orders.Daily_Orders_Form
            }
          />
        </Popup>
        <table
          id="table"
          style={{
            width: " 100%",
            borderCollapse: "collapse",
            border: "1px solid black",
            display: "none",
          }}
        >
          <tr>
            {headCells.map((DailyOrderList_Order, index) =>
              DailyOrderList_Order.label !== "Actions" ? (
                <th key={index} style={{ border: "1px solid black" }}>
                  {DailyOrderList_Order.label}
                </th>
              ) : null
            )}
          </tr>
          {props.Daliy_Order_List !== null
            ? recordsAfterPagingAndSorting().map(
                (DailyOrderList_Order, index) => (
                  <tr key={index}>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Date"
                    >
                      {misc
                        .formatDate(DailyOrderList_Order.Trans_Date)
                        .toString()}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Milk Qty "
                    >
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Milk_Qty
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Milk Rate "
                    >
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Milk_Rate
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Milk Amount"
                    >
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Milk_Amount
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Yog Qty "
                    >
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Yog_Qty
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Yog Rate "
                    >
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Yog_Rate
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Yog Amount"
                    >
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Yog_Amount
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Other Qty "
                    >
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Ghee_Qty
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Other Rate "
                    >
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Ghee_Rate
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Other Amount"
                    >
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Ghee_Amount
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Detail "
                    >
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Trans_Detail
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Payable"
                    >
                      {misc.currencyOnlyDecimal(
                        DailyOrderList_Order.Debit_Amount
                      )}
                    </td>
                  </tr>
                )
              )
            : null}
        </table>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dailyOrdersLanguage: state.language.language,
  Daliy_Order_List: state.DailyOrderList.Daliy_Order_List,
  totalPages: state.DailyOrderList.totalPages,
  currentPage: state.DailyOrderList.currentPage,
  totalOrders: state.DailyOrderList.totalOrders,
});

const mapDispatchToProps = (dispatch) => ({
  setDailyOrders: (orders) => {
    dispatch(setDailyOrders(orders));
  },
  DeleteDailyOrderFromReduxState: (OrderID, totalOrders, totalPages) => {
    dispatch(DeleteDailyOrderFromReduxState(OrderID, totalOrders, totalPages));
  },
  emptyDailyOrderList: () => {
    dispatch(emptyDailyOrderList());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(DailyOrders));
