import {deleteAccountsBalance} from '../utils/AccountsBalanceUtls'
const initilastate ={
    AccountsBalances_List:[],
    totalPages:"",
    currentPage:"",
    totalAccountsBalances:"",
    }

    const AccountsBalancesList =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "GETACCOUNTSBALANCES":
                console.log(Action.payload)
                return{
                    ...state,
                    AccountsBalances_List:[...state.AccountsBalances_List,...Action.payload.AccountsBalances],
                    totalPages:Action.payload.totalPages,
                    currentPage:Action.payload.currentPage,
                    totalAccountsBalances:Action.payload.totalAccountsBalances,
                    }
            break;
            case "DELETEACCOUNTSBALANCE":
                return{
                    ...state,
                    AccountsBalances_List:deleteAccountsBalance(state.AccountsBalances_List,Action.payload.accountsBalanceID),
                    totalAccountsBalances:Action.payload.totalAccountsBalances,
                    totalPages:Action.payload.totalPages,
                    }
            break;
            case "EMPTYACCOUNTSBALANCES":
                return{
                    ...state,
                    AccountsBalances_List: [],
                    totalPages:"",
                    currentPage:"",
                    totalAccountsBalances:"",
                    }
            break;
            default:
                return state
                break;
        }
    
    }
    
    export default AccountsBalancesList
    
    

