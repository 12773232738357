const initilastate ={
    userAuthKey: sessionStorage.getItem("accountingauthkey")?sessionStorage.getItem("accountingauthkey"):null,
    userName: sessionStorage.getItem("accountinguser")?sessionStorage.getItem("accountinguser"):'anonymus',
    userMobile:sessionStorage.getItem("usermobile")?sessionStorage.getItem("usermobile"):null,
    userShop: sessionStorage.getItem("usershop")?sessionStorage.getItem("usershop"):null,
    userShopAddress: sessionStorage.getItem("usershopaddress")?sessionStorage.getItem("usershopaddress"):null,
    usercontactperson: sessionStorage.getItem("usercontactperson")?sessionStorage.getItem("usercontactperson"):null,
    userType: sessionStorage.getItem("usertype")?sessionStorage.getItem("usertype"):null
    }

    const Auth =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "AUTHENTICATEUSER":
                console.log(Action.payload)
                sessionStorage.setItem("accountingauthkey",Action.payload.token)
                sessionStorage.setItem("accountinguser",Action.payload.user.userName)
                sessionStorage.setItem("usermobile",Action.payload.user.phoneNumber)
                sessionStorage.setItem("usershop",Action.payload.user.ShopName)
                sessionStorage.setItem("usershopaddress",Action.payload.user.shopAddress)
                sessionStorage.setItem("usercontactperson",Action.payload.user.contactPerson)
                sessionStorage.setItem("usertype",Action.payload.user.userType)
                return{
                    ...state,
                    userAuthKey: sessionStorage.getItem("accountingauthkey"),
                    userName: sessionStorage.getItem("accountinguser"),
                    userMobile:sessionStorage.getItem("usermobile"),
                    userShop: sessionStorage.getItem("usershop"),
                    userShopAddress: sessionStorage.getItem("usershopaddress"),
                    usercontactperson: sessionStorage.getItem("usercontactperson"),
                    userType: sessionStorage.getItem("usertype")
                    }
            break;
            case "LOGUOTUSER":
                return{
                    ...state,
                    userAuthKey: sessionStorage.getItem("accountingauthkey"),
                    userName: sessionStorage.getItem("accountinguser"),
                    userMobile:sessionStorage.getItem("usermobile"),
                    userShop: sessionStorage.getItem("usershop"),
                    userShopAddress: sessionStorage.getItem("usershopaddress"),
                    usercontactperson: sessionStorage.getItem("usercontactperson"),
                    userType: sessionStorage.getItem("usertype")
                    }
            break;
            default:
                return state
                break;
        }
    
    }
    
    export default Auth
    
    

