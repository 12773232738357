import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import TabView from "./components/TabView";
import Register from "./components/Auth/Register";
import Login from "./components/Auth/Login";
import Users from "./pages/Users/Users";
import Balance from "./pages/Balance/Balance";
import AccountsBalances from "./pages/AccountsBalances/AccountsBalances";
import Areas from "./pages/Areas/Areas";
import Receipts from "./pages/Receipts/Receipts";
import Customers from "./pages/Customers/Customers";
import CustomersPrint from "./pages/Customers/CustomerListPrint"; //Print customers
import ViewOrders from "./pages/Purchases/ViewOrders";
import DailyOrders from "./pages/DailyOrders/DailyOrders";
import AddOrder from "./pages/Purchases/AddOrder";
import { connect } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5B2B17",
      text: "#28666b",
    },
  },
});

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/"
              render={() =>
                props.userAuth ? (
                  <Redirect to={"/vieworders"} />
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
            <Route
              path="/login"
              render={() =>
                props.userAuth ? <Redirect to={"/vieworders"} /> : <Login />
              }
            />
            <Route
              path="/register"
              render={() =>
                props.userAuth ? <Redirect to={"/vieworders"} /> : <Register />
              }
            />
            <Route
              path="/users"
              render={() =>
                props.userAuth && props.userType == "1" ? (
                  <TabView>
                    <Users />
                  </TabView>
                ) : props.userAuth ? (
                  <Redirect to={"/vieworders"} />
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
            <Route
              path="/vieworders"
              render={() =>
                props.userAuth ? (
                  <TabView title="/View Orders">
                    <ViewOrders />
                  </TabView>
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
            <Route
              path="/dailyorders"
              render={() =>
                props.userAuth ? (
                  <TabView title="/Daily Orders">
                    <DailyOrders />
                  </TabView>
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
            <Route
              path="/addorder"
              render={() =>
                props.userAuth ? (
                  <TabView title="/Add Order">
                    <AddOrder />
                  </TabView>
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
            <Route
              path="/areas"
              render={() =>
                props.userAuth ? (
                  <TabView title="/Areas">
                    <Areas />
                  </TabView>
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
            <Route
              path="/receipt"
              render={() =>
                props.userAuth ? (
                  <TabView title="/Receipts">
                    <Receipts />
                  </TabView>
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
            <Route
              path="/accountsbalances"
              render={() =>
                props.userAuth ? (
                  <TabView title="/Accounts Balances">
                    <AccountsBalances />
                  </TabView>
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
            <Route
              path="/balance"
              render={() =>
                props.userAuth ? (
                  <TabView title="/Balance">
                    <Balance />
                  </TabView>
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
            <Route
              path="/customers"
              render={() =>
                props.userAuth ? (
                  <TabView title="/Customers">
                    <Customers />
                  </TabView>
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
            <Route
              path="/customersprint"
              render={() =>
                props.userAuth ? (
                  <TabView title="/Customers Print">
                    <CustomersPrint />
                  </TabView>
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
          </Switch>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}
const mapStateToProps = (state) => ({
  userAuth: state.Auth.userAuthKey,
  userType: state.Auth.userType,
});
export default connect(mapStateToProps)(App);
